import React, {useEffect, useState, useContext} from "react";
import {CartContext} from "../../Context/CartContext";
import { MegaContext } from "../../Context/MegaContext";
import {AuthenticationContext} from "../../Context/AuthenticationContext";
import {Link, useNavigate, useLocation, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import useGetInfo from "../../hooks/useGetInfo";
import Helmet from "react-helmet";

export default function Cart() {
    const authContext = useContext(AuthenticationContext);
    const contextCart = useContext(CartContext);
    const megaContext = useContext(MegaContext);
    const location = useLocation();
    const [device, setDevice] = useState();
    const [products, setProducts] = useState([]);
    const [detail, setDetail] = useState(authContext.user?.detail);
    const { cityItems, getCity } = useGetInfo();

    const orderComplete = async (e) => {
        e.target.setAttribute('disabled', true)

        if (!authContext.isAuthenticated) {
            toast.warning("Zəhmət olmasa sifarişi tamamlamaq üçün hesabınıza giriş edin");
            e.target.removeAttribute('disabled')
        } else {

            let data = {
                'first_name': document.querySelector('[name="first_name"]').value,
                'last_name': document.querySelector('[name="last_name"]').value,
                'email': authContext.user.email,
                'mobile': authContext.user.mobile,
                'address': authContext.user?.detail?.address ?? detail?.address,
                'city': authContext.user?.detail?.state?.id ?? detail?.state,
                'cart_id': contextCart.cart.id,
                'user_id': authContext.user.id,
                'payment_method': 1,
            };

            axios.interceptors.request.use(
                (config) => {
                    config.headers.authorization = `Bearer ${authContext.token}`;
                    return config;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
            let response = await axios.post(
                `${process.env.REACT_APP_API_URL}/complete-order`, data
            );

            response = response.data;

            if (response.status === "success") {
                localStorage.setItem("cart_id", response.cart_id);
                contextCart.setCartId(response.cart_id);
                toast.success(response.message);
                contextCart.getCart();
            }
            if (response.status === "warning") {
                let message = response.message;
                for (const value of Object.values(message)) {
                    for (let i = 0; i < value.length; i++) {
                        toast.warning(value[i]);
                    }
                }
            }
            e.target.removeAttribute('disabled')
        }
    }

    function updateCart(piece, product) {

        if (piece < 1) {
            toast.info("Minimum məhsul sayı 1 olmalıdır");
            piece = 1
        } else if (piece > product.price?.stock_piece) {
            toast.info("Məhsul sayı stock sayından çox ola bilməz");
            piece = product.price?.stock_piece
        }
        let newProducts = products.filter(item => {
            if (item.id === product.id) {
                item.piece = piece
            }
            return item;
        })
        setProducts(newProducts)

        contextCart.updateCart({
            cart_id: contextCart.cart.id,
            id: product.id,
            piece: piece
        })
    }

    const handleDetail = (e) => {
        const { name, value } = e.target;
        let newDetail = { ...detail};
        newDetail[name] = value;
        setDetail(newDetail);
    };

    const login = (e) => {
        megaContext.setPopUpLogin(true);
    }

    function deleteCart(product) {
        let newProducts = products.filter(item => item.id !== product.id)
        setProducts(newProducts)
        contextCart.deleteCart(product.id)
    }


    if (!products.length && contextCart.count) {
        setProducts(contextCart.cart.cart_products)
    }
    useEffect(() => {
        contextCart.getCart();
        getCity();

        if (window.innerWidth < 1024) {
            setDevice("mobile");
        } else {
            setDevice("desktop");
        }

        function deviceModel() {
            if (window.innerWidth < 1024) {
                setDevice("mobile");
            } else {
                setDevice("desktop");
            }
        }

        window.addEventListener("resize", deviceModel);
    }, [location]);
    if (contextCart.count) {
        return (
            <>
                <Helmet>
                    <title>Mega az onlayn alış veriş mağazası</title>
                    <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
                    <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
                </Helmet>
                <div className={`basket-page j-b-lk-basket ${device}`}>
                    <div className="basket-page__blocks-wrap j-order-details">
                        <div
                            className="basket-page__basket-form basket-form"
                        >
                            <div className="basket-form__content j-basket-form__content">
                                <div className="basket-form__basket-section basket-section">
                                    <div className="basket-section__header-tabs">
                                        <h1
                                            className="basket-section__header active"
                                            data-count={contextCart.count}
                                        >
                                            Səbət
                                        </h1>
                                    </div>

                                    {products.map(
                                        (product) => (
                                            <div className="basket-section__basket-list basket-list"
                                                 style={{marginBottom: '15px'}}>
                                                <div className="basket-list__accordion accordion j-b-list-title">
                                                    <div className="accordion__body j-b-list-content">
                                                        <div className="accordion__list">
                                                            <div>
                                                                <div
                                                                    className="accordion__list-item list-item j-b-basket-item">
                                                                    <div className="list-item__wrap">
                                                                        <div className="list-item__good">
                                                                            <Link
                                                                                to={`/product/${product.product.slug}`}
                                                                                className="img-plug list-item__good-img j-product-popup"
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        product
                                                                                            .product
                                                                                            .image
                                                                                            ? `${process.env.REACT_APP_URL}assets/img/products/${product.product.image.image_name}`
                                                                                            : null
                                                                                    }
                                                                                    alt={
                                                                                        product
                                                                                            .product
                                                                                            .image
                                                                                            ? product
                                                                                                .product
                                                                                                ?.image
                                                                                                ?.image_name
                                                                                            : null
                                                                                    }
                                                                                />
                                                                            </Link>
                                                                            <div
                                                                                className="list-item__good-info good-info">
                                                                                {device === "mobile" && (
                                                                                        <div
                                                                                            className="list-item__price">
                                                                                            {product
                                                                                                .product
                                                                                                .discount ? (
                                                                                                <>
                                                                                                    <div
                                                                                                        className="list-item__price-new">
                                                                                                        {(product.piece *
                                                                                                            (product
                                                                                                                    .price
                                                                                                                    .sale_price -
                                                                                                                (product
                                                                                                                        .price
                                                                                                                        .sale_price *
                                                                                                                    product
                                                                                                                        .product
                                                                                                                        .discount) /
                                                                                                                100)).toFixed(2)}

                                                                                                        ₼
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="list-item__price-old">
                                                                                                        {(product.piece *
                                                                                                            product
                                                                                                                .price
                                                                                                                .sale_price).toFixed(2)}

                                                                                                        ₼
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <div
                                                                                                    className="list-item__price-new">
                                                                                                    {(product.piece *
                                                                                                        product
                                                                                                            .price
                                                                                                            .sale_price).toFixed(2)}

                                                                                                    ₼
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                <Link
                                                                                    className="good-info__title j-product-popup"
                                                                                    to={`/product/${product.product.slug}`}
                                                                                >
																					<span
                                                                                        className="good-info__good-name">
																						{
                                                                                            product
                                                                                                .product
                                                                                                .product_name
                                                                                        }
																					</span>
                                                                                </Link>
                                                                                <div className="good-info__properties">
                                                                                    <p className="good-info__color">
                                                                                        {product.color_id ? (
                                                                                            <>
																								<span
                                                                                                    className="good-info__item">
																									Rəng:
																								</span>
                                                                                                <span
                                                                                                    className="good-info__item">
																									{
                                                                                                        product
                                                                                                            .color
                                                                                                            .title
                                                                                                    }
																								</span>
                                                                                            </>
                                                                                        ) : null}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="good-info__properties">
                                                                                    <p className="good-info__color">
                                                                                        {product.size_id ? (
                                                                                            <>
																								<span
                                                                                                    className="good-info__item">
																									Ölçü:
																								</span>
                                                                                                <span
                                                                                                    className="good-info__item">
																									{
                                                                                                        product
                                                                                                            .size
                                                                                                            .name
                                                                                                    }
																								</span>
                                                                                            </>
                                                                                        ) : null}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="list-item__count count">
                                                                            <div className="count__wrap">
                                                                                <div className="count__input-number">
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => updateCart(--product.piece, product)}
                                                                                        className="count__minus minus"
                                                                                    />
                                                                                    <input
                                                                                        type="text"
                                                                                        autoComplete="off"
                                                                                        maxLength={
                                                                                            product.price?.stock_piece
                                                                                        }
                                                                                        className="in_tb j-tb-qnt count__numeric ignore"
                                                                                        value={
                                                                                            product.piece
                                                                                        }
                                                                                    />
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => updateCart(++product.piece, product)}
                                                                                        className="count__plus plus"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="list-item__btn btn">
                                                                                <button
                                                                                    className="btn__del j-basket-item-del"
                                                                                    onClick={() => deleteCart(product)}>
																					<span>
																						Sil
																					</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        {device ==
                                                                            "desktop" && (
                                                                                <div className="list-item__price">
                                                                                    {product
                                                                                        .product
                                                                                        .discount ? (
                                                                                        <>
                                                                                            <div
                                                                                                className="list-item__price-new">
                                                                                                {(product.piece *
                                                                                                    (product
                                                                                                            .price
                                                                                                            .sale_price -
                                                                                                        (product
                                                                                                                .price
                                                                                                                .sale_price *
                                                                                                            product
                                                                                                                .product
                                                                                                                .discount) /
                                                                                                        100)).toFixed(2)}

                                                                                                ₼
                                                                                            </div>
                                                                                            <div
                                                                                                className="list-item__price-old">
                                                                                                {(product.piece *
                                                                                                    product
                                                                                                        .price
                                                                                                        .sale_price).toFixed(2)}

                                                                                                ₼
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <div
                                                                                            className="list-item__price-new">
                                                                                            {(product.piece *
                                                                                                product
                                                                                                    .price
                                                                                                    .sale_price).toFixed(2)}

                                                                                            ₼
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>

                                {
                                    authContext.user && (
                                        <div className="basket-form__basket-section basket-section basket-delivery j-b-basket-delivery">
                                            <div className="basket-section__header-wrap">
                                                <h2 className="basket-section__header hide-mobile">
                                                    Sifariş məlumatları
                                                </h2>
                                            </div>
                                            <div className="basket-delivery__methods">
                                                <div
                                                    className="contents"
                                                >
                                                    <div
                                                        className="contents__item contents__courier active"
                                                    >
                                                        <div
                                                            className="contents__wrap isSelectedAddress"
                                                        >
                                                            <div className="contents__selected-address selected-address">
                                                                <div className="selected-address__address address">
															<span className="selected-address__label">
																Çatdırılma ünvanı
															</span>
                                                                    <span className="selected-address__data address__data">
																{authContext.user?.detail?.state ? detail?.state?.name + ", " : null} {authContext.user?.detail?.address}
															</span>
                                                                </div>
                                                            </div>
                                                            <div className="data-delivery">
                                                                <div
                                                                    className="data-delivery__list"
                                                                >
                                                                    <div className="data-delivery__item">
                                                                        <div className="data-delivery__info-date info-date ">
                                                                            <div className="info-date__delivery">
																		<span className="info-date__delivery-free">
																		Çatdırılma məbləği: Pulsuz çatdırılma
																		</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="data-delivery">
                                                                <div
                                                                    className="data-delivery__list"
                                                                >
                                                                    <div className="data-delivery__item">
                                                                        <div className="data-delivery__info-date info-date ">
                                                                            <div className="info-date__delivery">
																		<span className="info-date__delivery-free">
																		Çatdırılma müddəti: {authContext.user && authContext.user?.detail?.state ? authContext.user?.detail?.state?.delivery_days + " gün" : null}
																		</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    authContext.user && (
                                        <div className="basket-section__wrap">
                                            <div
                                                className="basket-form__basket-section basket-section j-b-basket-user-data basket-user-data">
                                                <div className="basket-section__header-wrap">
                                                    <div className="basket-section__header-info">
                                                        <h2 className="basket-section__header">
                                                            Məlumatlarınız
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="user-data__form">
                                                    <div
                                                        className="form-block"
                                                        style={{display: "flex"}}
                                                    >
                                                        <label
                                                            className="form-block__label"
                                                            htmlFor={1}
                                                        >
													<span className="form-block__name">
														Ad
													</span>
                                                            <span className="form-block__text-decor">
														*
													</span>
                                                        </label>
                                                        <div className="form-block__input-wrap">
                                                            <input
                                                                id={1}
                                                                name="first_name"
                                                                autoComplete="off"
                                                                value={authContext.user ? authContext.user.first_name : null}
                                                                className="val-msg"
                                                            />
                                                        </div>
                                                        <div className="form-block__message">
                                                            <span className="j-b-first-name-err"/>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="form-block"
                                                        style={{display: "flex"}}
                                                    >
                                                        <label
                                                            className="form-block__label"
                                                            htmlFor={2}
                                                        >
													<span className="form-block__name">
														Soyad
													</span>
                                                            <span className="form-block__text-decor">
														*
													</span>
                                                        </label>
                                                        <div className="form-block__input-wrap">
                                                            <input
                                                                id={2}
                                                                name="last_name"
                                                                autoComplete="off"
                                                                value={authContext.user ? authContext.user.last_name : null}
                                                                className="val-msg"
                                                            />
                                                        </div>
                                                        <div className="form-block__message">
                                                            <span className="j-b-last-name-err"/>
                                                        </div>
                                                    </div>
                                                    {
                                                        !authContext.user?.detail?.address && (
                                                            <div
                                                                className="form-block"
                                                                style={{display: "flex"}}
                                                            >
                                                                <label
                                                                    className="form-block__label"
                                                                    htmlFor={4}
                                                                >
													<span className="form-block__name">
														Ünvan
													</span>
                                                                    <span className="form-block__text-decor">
														*
													</span>
                                                                </label>
                                                                <div className="form-block__input-wrap">
                                                                    <input
                                                                        id={4}
                                                                        name="address"
                                                                        autoComplete="off"
                                                                        className="val-msg"
                                                                        onChange={handleDetail}
                                                                    />
                                                                </div>
                                                                <div className="form-block__message">
                                                                    <span className="j-b-first-name-err"/>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        !authContext.user?.detail?.state && (
                                                            <div
                                                                className="form-block"
                                                                style={{display: "flex"}}
                                                            >
                                                                <label
                                                                    className="form-block__label"
                                                                    htmlFor={5}
                                                                >
													<span className="form-block__name">
														Şəhər
													</span>
                                                                    <span className="form-block__text-decor">
														*
													</span>
                                                                </label>
                                                                <div className="form-block__input-wrap">
                                                                    <select name="state" id="state" className="val-msg" onChange={handleDetail}>
                                                                        <option value>Şəhər seç</option>
                                                                        {
                                                                            cityItems?.map(item =>
                                                                                <option value={item.id}>{item.name}</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="form-block__message">
                                                                    <span className="j-b-first-name-err"/>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    <div className="form-block form-block--phone">
                                                        <label
                                                            className="form-block__label"
                                                        >
													<span className="form-block__name">
														Mobil nömrə
													</span>
                                                            <span className="form-block__text-decor">
														*
													</span>
                                                        </label>
                                                        <div className="form-block__input-wrap">
                                                            <input
                                                                type="text"
                                                                name="mobile"
                                                                className="c-input-base"
                                                                autoComplete="off"
                                                                disabled="disabled"
                                                                value={authContext.user ? authContext.user.mobile : null}
                                                                placeholder=""
                                                            />
                                                        </div>
                                                        <div className="form-block__message sms-code-msg">
                                                            <span className="j-b-contact-phone-err"/>
                                                        </div>
                                                    </div>
                                                    <div className="form-block form-block--email">
                                                        <label className="form-block__label">
													<span className="form-block__name">
														E-Poçt
													</span>
                                                            <span className="form-block__text-decor">
														*
													</span>
                                                        </label>
                                                        <div className="form-block__input-wrap">
                                                            <input
                                                                autoComplete="off"
                                                                name="email"
                                                                type="text"
                                                                disabled="disabled"
                                                                value={authContext.user ? authContext.user.email : null}
                                                            />
                                                        </div>
                                                        <div className="form-block__message">
                                                            <span className="field-validation-valid j-b-email-err"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                            <div className="basket-form__sidebar j-basket-form__sidebar">
                                <div
                                    className="j-b-basket-footer"
                                    data-link="{include #data tmpl='basketFooter'}"
                                >
                                    <div className="basket-section basket-order">
                                        <div className="basket-order__b-top b-top">
                                            <p className="b-top__total line">
                                                <span>Ümumi</span>
                                                <span>
													<span>
														{contextCart.total.toFixed(2)} ₼
													</span>
												</span>
                                            </p>
                                        </div>
                                        <div className="basket-order__b-btn b-btn">
                                            {
                                                authContext.isAuthenticated ? (
                                                    <button
                                                        type="button"
                                                        onClick={orderComplete}
                                                        className="b-btn-do-order btn-main j-btn-confirm-order"
                                                        name="ConfirmOrderByRegisteredUser"
                                                    >
                                                        Sifariş ver
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        onClick={login}
                                                        className="b-btn-do-order btn-main j-btn-confirm-order"
                                                        name="ConfirmOrderByRegisteredUser"
                                                    >
                                                        Daxil ol
                                                    </button>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="loader"
                        data-link="visible{:modify}"
                        style={{display: "none"}}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <Helmet>
                    <title>Mega az onlayn alış veriş mağazası</title>
                    <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
                    <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
                </Helmet>

                <div className="basket-page j-b-lk-basket desktop">
                    <div className="basket-page__blocks-wrap j-order-details">
                        <div className="basket-page__basket-empty basket-empty">
                            <h1>Səbətdə məhsul yoxdur</h1>
                            <div className="basket-empty__text">
                                Əsas səhifədən başlayın və ya istifadə edin konkret bir şey tapmaq üçün axtarış edin
                            </div>
                            <Link className="btn-main" to={'/'}>Əsas səhifəyə keçin</Link>
                            <div className="visitedGoods-in-basket">
                                <div
                                    className="i-visited-goods catalog-page__recently-watched j-recent-goods-container"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="loader" style={{display: "none"}}/>
                </div>
            </>
        );
    }
}
