import React, { Component } from 'react'
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import axios from 'axios';
import serialize from 'form-serialize';
import { toast } from 'react-toastify';
import { AuthenticationContext } from "../../Context/AuthenticationContext";
import { CartContext } from "../../Context/CartContext";
import Helmet from "react-helmet";
// import { MegaContext } from "../../Context/MegaContext";

export default class Reset extends Component {

    constructor (props){
        super(props);

        this.state = {
            email: null,
            code: null,
            sendCode: true,
            confirmCode: false,
            changePassword: false,
            time: {
                m: 0,
                s: 59
            },
            seconds: 59
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    static contextType = AuthenticationContext;
    static cartContext = CartContext;

    secondsToTime(secs){
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "m": minutes,
            "s": seconds
        };
        return obj;
    }
    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
    }
    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }
    stopTimer () {
        clearInterval(this.timer);
        this.timer = 0;
        this.setState({
            seconds: 59
        });
    }
    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        if (seconds == 0) {
            clearInterval(this.timer);
            this.setState({
                sendCode: true,
                confirmCode: false,
                changePassword: false,
                seconds: 59
            });
            this.timer = 0;
            this.disabledButton(false)
        }
    }
    disabledButton (value) {
        if (value) {
            document.querySelectorAll('button[type="submit"]').forEach((b, i) => {
                b.setAttribute('disabled', 'disabled')
            });
        } else {
            document.querySelectorAll('button[type="submit"]').forEach((b, i) => {
                b.removeAttribute('disabled')
            });
        }
    }
    sendCode = async event => {
        event.preventDefault();
        this.disabledButton(true)
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/email-send-code`, {
            email: this.state.email
        })
        response = response.data
        if (response.status === 'success') {
            toast.success(response.message);
            this.startTimer();
            this.setState({
                sendCode: false,
                confirmCode: true,
                changePassword: false,
            })
        }
        else if (response.status === 'warning') {
            let message = response.message;
            for (const value of Object.values(message)) {
                for (let i = 0; i < value.length; i++) {
                    toast.warning(value[i])
                }
            }
        }
        else if (response.status === 'error') {
            toast.error(response.message)
        }
        this.disabledButton(false)
    }
    confirmCode = async event => {
        event.preventDefault();
        this.disabledButton(true)
        let data = serialize(event.target, { hash: true })
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/confirm-code`, data)
        response = response.data
        if (response.status === 'success') {
            // toast.success(response.message)
            this.stopTimer();
            this.setState({
                sendCode: false,
                confirmCode: false,
                changePassword: true,
            })
        }
        if (response.status === 'warning') {
            let message = response.message;
            for (const value of Object.values(message)) {
                for (let i = 0; i < value.length; i++) {
                    toast.warning(value[i])
                }
            }
        }
        if (response.status === 'error') {
            toast.error(response.message)
        }
        this.disabledButton(false)
    }
    resetPassword = async event => {
        event.preventDefault();
        this.disabledButton(true)
        let data = serialize(event.target, { hash: true })
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/change-password`, data)
        response = response.data
        if (String(response.status) === 'success') {
            window.location.href = '/auth/login'
            toast.success(response.message)
        }
        if (String(response.status) === 'warning') {
            let message = response.message;
            for (const value of Object.values(message)) {
                for (let i = 0; i < value.length; i++) {
                    toast.warning(value[i])
                }
            }
        }
        if (String(response.status) === 'error') {
            toast.error(response.message)
        }
        this.disabledButton(false)
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>Mega az onlayn alış veriş mağazası</title>
                    <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
                    <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
                </Helmet>

                <div className="sign-in-page">
                    {
                        this.state.sendCode && (
                            <div className="sign-in-page__wrap">
                                <form id="spaAuthForm" onSubmit={this.sendCode}>
                                    <h2 className="sign-in-page__title" >Şifrənizi yeniləyin</h2>
                                    <div className="sign-in-page__login login">
                                        <div className="login__phone form-block form-block--phone-dropdown">
                                            <label className="form-block__label">
                                                <span className="form-block__name">Email</span>
                                            </label>
                                            <div className="form-block__dropdown-wrap">
                                                <div className="form-block__input">
                                                    <input className="input-item" type="text" name="email" onChange={(e) => this.setState({email: e.target.value})}  />
                                                </div>
                                            </div>
                                        </div>
                                        <button id="requestCode" className="login__btn btn-main-lg" type="submit" >Növbəti</button>
                                    </div>

                                    <div className="sign-in-page__public p-0"><Link to="/auth/login"><PersonIcon /> <span style={{ verticalAlign: 'middle' }}>Daxil ol</span></Link></div>
                                </form>
                            </div>
                        )
                    }
                    {
                        this.state.confirmCode && (
                            <div className="sign-in-page__wrap">
                                <form id="spaAuthForm" onSubmit={this.confirmCode}>
                                    <input type="hidden" name='email' value={this.state.email}/>
                                    <h2 className="sign-in-page__title">Email adresinizi təsdiqləyin</h2>
                                    <div className="sign-in-page__login login">
                                        <div className="login__phone form-block form-block--phone-dropdown">
                                            <label className="form-block__label justify-content-between w-100">
                                                <span className="form-block__name">Təsdiqləmə kodu</span>
                                                <span className="form-block__name">{this.state.time.m}:{this.state.time.s}</span>
                                            </label>
                                            <div className="form-block__dropdown-wrap">
                                                <div className="form-block__input">
                                                    <input className="input-item" type="text" name="code" />
                                                </div>
                                            </div>
                                        </div>
                                        <button className="login__btn btn-main-lg" type="submit" >Növbəti</button>
                                    </div>

                                    <div className="sign-in-page__public p-0"><Link to="/auth/login"><PersonIcon /> <span style={{ verticalAlign: 'middle' }}>Daxil ol</span></Link></div>
                                </form>
                            </div>
                        )
                    }
                    {
                        this.state.changePassword && (
                            <div className="sign-in-page__wrap">
                                <form id="spaAuthForm" onSubmit={this.resetPassword}>
                                    <input type="hidden" name='email' value={this.state.email}/>
                                    <h2 className="sign-in-page__title" >Şifrənizi yeniləyin</h2>
                                    <div className="sign-in-page__login login">
                                        <div className="login__phone form-block form-block--phone-dropdown">
                                            <label className="form-block__label">
                                                <span className="form-block__name">Yeni şifrə</span>
                                            </label>
                                            <div className="form-block__dropdown-wrap">
                                                <div className="form-block__input">
                                                    <input className="input-item" type="password" name="password" />
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="login__phone form-block form-block--phone-dropdown">
                                            <label className="form-block__label">
                                                <span className="form-block__name">Yeni şifrə (təkrar)</span>
                                            </label>
                                            <div className="form-block__dropdown-wrap">
                                                <div className="form-block__input">
                                                    <input className="input-item" type="password" name="password_confirmation" />
                                                </div>
                                            </div>
                                        </div>
                                        <button className="login__btn btn-main-lg" type="submit" >Yenilə</button>
                                    </div>

                                    <div className="sign-in-page__public p-0"><Link to="/auth/login"><PersonIcon /> <span style={{ verticalAlign: 'middle' }}>Daxil ol</span></Link></div>
                                </form>
                            </div>
                        )
                    }

                </div>
            </>
        )
    }
}
