import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
export const AuthenticationContext = React.createContext();
AuthenticationContext.displayName = "AuthenticationContext";

class AuthenticationContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      user: null,
      token: null,
      expiry: null,
    };
  }
  componentDidMount = async (event) => {
    if (localStorage.hasOwnProperty("authentication")) {
      let authentication = JSON.parse(localStorage.getItem("authentication"));

      if(Date.parse(authentication.expire) > new Date()){
        this.setState({
          isAuthenticated: true,
          user: authentication.user,
          token: authentication.token,
          expiry: authentication.expire,
        });
      }
      else{
        axios.interceptors.request.use(
          (config) => {
            config.headers.authorization = `Bearer ${authentication.token}`;
            return config;
          },
          (error) => {
            return Promise.reject(error);
          }
        );
        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/control`
        );
  
        response = response.data;
        if (response.status === "success") {
          this.setState({
            isAuthenticated: true,
            user: authentication.user,
          });
          
        }

        if (response.status === "error") {
          this.setState({
            isAuthenticated: false,
            user: null,
            token: null,
            expiry: null,
          });
          localStorage.removeItem("authentication");
        }
      }

      
     
    }
  };
  authLogin = (authentication) => {
    this.setState({
      isAuthenticated: true,
      user: authentication.user,
      token: authentication.token,
      expire: authentication.expire,
    });
  };
  authLogout = () => {
    this.setState({
      isAuthenticated: false,
      user: null,
      token: null,
      expiry: null,
    });
  };
  updateData = async (data) => {
    let authentication = JSON.parse(localStorage.getItem("authentication"));

    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${this.state.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/data/${this.state.user.id}`,
      data
    );

    response = response.data;
    if (response.status === "success") {
      this.setState({
        user: response.user,
      });
      toast.success(response.message);
      authentication.user = response.user;
      localStorage.setItem("authentication", JSON.stringify(authentication));
    }
    if (response.status === "warning") {
      let message = response.message;
      for (const value of Object.values(message)) {
        for (let i = 0; i < value.length; i++) {
          toast.warning(value[i]);
        }
      }
    }
  };
  updateDetail = async (data) => {
    let authentication = JSON.parse(localStorage.getItem("authentication"));

    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${this.state.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/detail/${this.state.user.id}`,
      data
    );

    response = response.data;
    if (response.status === "success") {
      this.setState({
        user: response.user,
      });
      toast.success(response.message);
      authentication.user = response.user;
      localStorage.setItem("authentication", JSON.stringify(authentication));
    }
    if (response.status === "warning") {
      let message = response.message;
      for (const value of Object.values(message)) {
        for (let i = 0; i < value.length; i++) {
          toast.warning(value[i]);
        }
      }
    }
  };
  updatePassword = async (data) => {

    axios.interceptors.request.use(
      (config) => {
        config.headers.authorization = `Bearer ${this.state.token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/password/${this.state.user.id}`,
      data
    );

    response = response.data;
    if (response.status === "success") {
      toast.success(response.message);
    }
    if (response.status === "warning") {
      let message = response.message;
      for (const value of Object.values(message)) {
        for (let i = 0; i < value.length; i++) {
          toast.warning(value[i]);
        }
      }
    }
  };
  render() {
    return (
      <AuthenticationContext.Provider
        value={{
          ...this.state,
          authLogin: this.authLogin,
          authLogout: this.authLogout,
          updateData: this.updateData,
          updateDetail: this.updateDetail,
          updatePassword: this.updatePassword,
        }}
      >
        {this.props.children}
      </AuthenticationContext.Provider>
    );
  }
}

export default AuthenticationContextProvider;
