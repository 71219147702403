import axios from "axios";


class SliderRepository {
    async getSliders() {

        const sliders = await axios.get(`${process.env.REACT_APP_API_URL}/sliders`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return sliders;
    }
}


export default new SliderRepository();