import axios from "axios";


class BrandRepository {
    async getBrands() {

        const brands = await axios.get(`${process.env.REACT_APP_API_URL}/brands`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return brands;
    }
    async getBrand(params) {

        const brand = await axios.post(`${process.env.REACT_APP_API_URL}/brand`, params)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return brand;
    }
    async getProducts(params) {

        const products = await axios.post(`${process.env.REACT_APP_API_URL}/brand/products`, params)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return products;
    }
}


export default new BrandRepository();