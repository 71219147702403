import { useState } from 'react'

import InfoRepository from '../repositories/InfoRepository';

export default  function useGetInfo() {

    const [infoItems, setInfoItems] = useState(null);
    const [cityItems, setCityItems] = useState(null);
    const [aboutItems, setAboutItems] = useState(null);
    const [privacyItems, setPrivacyItems] = useState(null);
    const [shippingReturnItems, setShippingReturnItems] = useState(null);


    return {
        infoItems,
        cityItems,
        privacyItems,
        shippingReturnItems,
        aboutItems,

        getInfo: async () => {
            let responseData = await InfoRepository.getInfo();


            if (responseData) {
                for (const favicon of document.querySelectorAll(".app__favicon")) {
                    favicon.href = `${process.env.REACT_APP_URL}assets/img/${responseData.data.favicon}`
                }

                // document.querySelector(".app__description").setAttribute('content', responseData.data.description)
                // document.querySelector(".app__keyword").setAttribute('content', responseData.data.keywords)
                // document.querySelector("title").innerHTML = responseData.data.title
                setInfoItems(responseData.data);
            }
        },
        getCity: async () => {
            let responseData = await InfoRepository.getCity();

            if (responseData) {
                setCityItems(responseData.data);
            }
        },
        getAbout: async () => {
            let responseData = await InfoRepository.getAbout();

            if (responseData) {

                setAboutItems(responseData.data);
            }
        },
        getPrivacy: async () => {
            let responseData = await InfoRepository.getPrivacy();

            if (responseData) {

                setPrivacyItems(responseData.data);
            }
        },
        getShippingReturn: async () => {
            let responseData = await InfoRepository.getShippingReturn();

            if (responseData) {

                setShippingReturnItems(responseData.data);
            }
        }
    }
}

