import React, { useEffect } from "react";
import {Link, useLocation} from "react-router-dom";
import useGetBrands from "../../hooks/useGetBrands";
import Helmet from "react-helmet";


export default function Brands() {
    const location = useLocation()
  const { brandItems, getBrands } = useGetBrands();
  useEffect(() => {
    getBrands();
  }, [location]);
  return (
    <>
        <Helmet>
            <title>Mega az onlayn alış veriş mağazası</title>
            <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
            <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
        </Helmet>

      <div id="brandPage" className="brands-letter-page">
        <div className="brands-letter-page__brands-list brands-list">
          <ul className="brands-list__list">
            {brandItems
              ? brandItems.map((item) => (
                  <li className="brands-list__item j-brand-catalog-link">
                    <Link to={`/brand/${item.slug}`}>
                      <div className="brands-list__img-wrap img-plug">
                        <img
                          width={120}
                          height={50}
                          loading="lazy"
                          src={`${process.env.REACT_APP_URL}assets/img/brand/${item.image}`}
                          alt={item.name}
                        />
                      </div>
                      <span>{item.name}</span>
                    </Link>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
    </>
  );
}
