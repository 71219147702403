import axios from "axios";


class OrdersRepository {
    async getOrders(params) {

        const orders = await axios.post(`${process.env.REACT_APP_API_URL}/orders`, params)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return orders;
    }
    async getOrder(params) {

        const order = await axios.post(`${process.env.REACT_APP_API_URL}/order`, params)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return order;
    }
}

export default new OrdersRepository();