import axios from "axios";


class ProductsRepository {
    async getProducts(payload = null) {

        const products = await axios.get(`${process.env.REACT_APP_API_URL}/products?recently=${payload}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return products;
    }
    async getDiscounts(payload) {

        const discounts = await axios.post(`${process.env.REACT_APP_API_URL}/discounts/products`, payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return discounts;
    }
    async getBestSelling(payload) {

        const bestSelling = await axios.post(`${process.env.REACT_APP_API_URL}/best-selling/products`, payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return bestSelling;
    }
    async getWishList() {

        let authentication = JSON.parse(localStorage.getItem("authentication"));
        const products = await axios.get(`${process.env.REACT_APP_API_URL}/wishlist/${authentication.user.id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return products;
    }
    async getProduct(slug) {

        const product = await axios.get(`${process.env.REACT_APP_API_URL}/product/${slug}`, )
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return product;
    }
}


export default new ProductsRepository();
