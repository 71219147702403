import React, { useState, useContext, useEffect } from "react";
import { MegaContext } from "../Context/MegaContext";
import image from "../assets/images/picturedrophere.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthenticationContext } from "../Context/AuthenticationContext";
import FormData from "form-data";

export default function PopUpCropImage() {
	const context = useContext(MegaContext);
	const authContext = useContext(AuthenticationContext);
	const [data, setData] = useState(null);
	const changeFile = (e) => {
		let output = document.querySelector(".uploaded__image");
		if (e.target.files.length > 0) {
			output.src = URL.createObjectURL(e.target.files[0]);
			output.onload = function () {
				URL.revokeObjectURL(output.src);
			};

			setData(e.target.files[0]);
		} else {
			setData(null);
            output.src = image;
		}
	};
	const uploadPhoto = async () => {
		if (!data) {
			toast.error("Şəkil seçilməyib");
			return false;
		}

		let formData = new FormData();
		formData.append("image", data);

		let authentication = JSON.parse(localStorage.getItem("authentication"));

		axios.interceptors.request.use(
			(config) => {
				config.headers.authorization = `Bearer ${authentication.token}`;
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		const response = await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}/user/image/${authentication.user.id}`,
			data: formData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		if (response.status === "success") {
			toast.success(response.message);
			authentication.user = response.user;
			localStorage.setItem(
				"authentication",
				JSON.stringify(authentication)
			);
            // authContext.user
            context.setCropImagePopUp(false)
		}
		if (response.status === "warning") {
			let message = response.message;
			for (const value of Object.values(message)) {
				for (let i = 0; i < value.length; i++) {
					toast.warning(value[i]);
				}
			}
		}
	};
	return (
		<>
			<div
				className="i-popup-image-crop round-image shown"
				style={{
					zIndex: 301,
					height: "95vh",
					width: "95vw",
					opacity: 1,
					display: "block",
					top: "2.5vh",
					left: 0,
					right: 0,
					marginLeft: "auto",
					marginRight: "auto",
				}}
			>
				<a
					href="#"
					className="j-close popup__close close"
					onClick={() => context.setCropImagePopUp(false)}
				/>
				<div className="popup__content">
					<h2 className="popup__header">Şəkil yükləyin</h2>
					<div className="popup__top-wrap">
						<p className="popup__text">
							Profil şəklinizi seçilmiş sahəyə yerləşdirin
						</p>
					</div>
					<div className="popup__crop-container j-b-popup-content mx-0">
						<div
							className="cropper-container row justify-content-center align-items-center mx-0"
							touch-action="none"
						>
							<input
								type="file"
								name="user_image"
								id="user__image"
								onChange={(e) => changeFile(e)}
							/>
							<label
								htmlFor="user__image"
								className="user__image__label"
							>
								<img
									src={image}
									alt="The image to crop"
									className="cropper-hide uploaded__image"
								/>
							</label>
						</div>
					</div>
					<div className="popup__btn-block">
						<button
							className="btn-main"
							type="button"
							onClick={uploadPhoto}
						>
							Təsdiqlə
						</button>
					</div>
				</div>
			</div>
			<div
				className="i-overlay initially-hidden j-custom-overlay"
				style={{ zIndex: 300 }}
				onClick={() => context.setCropImagePopUp(false)}
			/>
		</>
	);
}
