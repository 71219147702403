import React, { useEffect } from "react";
import useGetInfo from "../hooks/useGetInfo";
import axios from 'axios';
import serialize from 'form-serialize';
import { toast } from 'react-toastify';
import Helmet from "react-helmet";

export default function Contact() {
  const { infoItems, getInfo } = useGetInfo();
  useEffect(() => {
    getInfo();
  }, []);

  const send = async event => {

    event.preventDefault();
    var data = serialize(event.target, { hash: true })

    let response = await axios.post(`${process.env.REACT_APP_API_URL}/send-message`, data)

    response = response.data

    if (response.status === 'success') {
        toast.success(response.message)
        event.target.reset()
    }
    if (response.status === 'warning') {
        let message = response.message;
        for (const value of Object.values(message)) {
            for (let i = 0; i < value.length; i++) {
                toast.warning(value[i])
            }
        }
    }

}
  return (
    <>
      <Helmet>
        <title>Mega az onlayn alış veriş mağazası</title>
        <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
        <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
      </Helmet>

      <div className="service-page">
        <div className="service-page__menu">
          <div className="service-page__content j-service-page-content">
            <div className="service-page-iframe">
              <div className="page-contacts">
                <h1 className="c-h1">Əlaqə</h1>
                <div className="contacts-list">
                  <div className="contacts-list-item contacts-list-item-lg-25 contacts-list-item-md-50 contacts-list-item-100">
                    <h2 className="c-h2 title">Email</h2>
                    <p className="c-text-base gray">
                      Suallarınızı email üzərindən bizə göndərə bilərsiniz
                    </p>
                    <a
                      className="c-link-out link"
                      href={`mailto:${infoItems ? infoItems.email : null}`}
                    >
                      {infoItems ? infoItems.email : null}
                    </a>
                  </div>

                  <div className="contacts-list-item contacts-list-item-lg-25 contacts-list-item-md-50 contacts-list-item-100">
                    <h2 className="c-h2 title">Telefon</h2>
                    <p className="c-text-base gray">
                      Daha operativ əlaqə üçün zəng edin
                    </p>
                    <a
                      className="c-link-out link"
                      href={`tel:${infoItems ? infoItems.mobile : null}`}
                    >
                      {infoItems ? infoItems.mobile : null}
                    </a>
                    <a
                      className="c-link-out link"
                      style={{ bottom: "64px" }}
                      href={`tel:${infoItems ? infoItems.phone : null}`}
                    >
                      {infoItems ? infoItems.phone : null}
                    </a>
                  </div>

                  <div className="contacts-list-item contacts-list-item-md-50 contacts-list-item-100 contacts-list-address">
                    <h2 className="c-h2 title">Fiziki ünvan</h2>
                    <p className="c-text-base gray address-item">
                      <span className="address-item-value">
                        {infoItems ? infoItems.address : null}
                      </span>
                    </p>
                  </div>
                </div>
                <form id="contact" onSubmit={send}>
                  <div className="row">
                    <div className="col-12">
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-4 py-3">
                          <div className="form-block form-block--phone-dropdown">
                            <label className="form-block__label">
                              <span className="form-block__name">
                                Ad <span>*</span>
                              </span>
                            </label>
                            <div className="form-block__dropdown-wrap">
                              <div className="form-block__input">
                                <input
                                  className="input-item"
                                  type="text"
                                  name="name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 py-3">
                          <div className="form-block form-block--phone-dropdown">
                            <label className="form-block__label">
                              <span className="form-block__name">
                                Email <span>*</span>
                              </span>
                            </label>
                            <div className="form-block__dropdown-wrap">
                              <div className="form-block__input">
                                <input
                                  className="input-item"
                                  type="text"
                                  name="email"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-8 py-3">
                          <div className="form-block form-block--phone-dropdown">
                            <label className="form-block__label">
                              <span className="form-block__name">
                                Mesaj <span>*</span>
                              </span>
                            </label>
                            <div className="form-block__dropdown-wrap">
                              <div className="form-block__input">
                                <textarea
                                  name="message"
                                  className="input-item form-control"
                                  cols="30"
                                  rows="10"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-8 py-3">
                          <button
                            className="login__btn btn-main-lg"
                            type="submit"
                          >
                            Göndər
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
