import axios from "axios";


class BannerRepository {
    async getBanners() {

        const banners = await axios.get(`${process.env.REACT_APP_API_URL}/banners`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return banners;
    }
}


export default new BannerRepository();