import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import "./assets/style.scss";
import {BrowserRouter} from "react-router-dom";
import {StaticRouter} from "react-router-dom/server";

if (process.env.NODE_ENV === 'development') {
    ReactDOM.render(
        <BrowserRouter><App/></BrowserRouter>,
        document.getElementById("root")
    );
} else {
    ReactDOM.hydrate(
        <BrowserRouter><App/></BrowserRouter>,
        document.getElementById("root")
    );
}



