import React, { useContext, useEffect, useState } from 'react'
import { MegaContext } from "../Context/MegaContext";
import axios from "axios";
import {Link} from "react-router-dom";

export default function SearchPopUp() {
    const context = useContext(MegaContext);
    const [ products, setProducts] = useState();
    const searchProducts = async (wanted) => {
        const products = await axios.post(`${process.env.REACT_APP_API_URL}/search/products/${wanted}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        const data = products.products.length > 0 ? products.products : null
        setProducts(data)
    }
    const searchInput = (e) => {
        console.log(e.target.value)
        if(e.target.value.length > 0) {
            document.querySelector('.search-catalog__autocomplete').classList.remove('d-none')
            document.querySelector('.search-catalog__block').classList.add('search-catalog--active')
            searchProducts(e.target.value)
        }
        else {
            setProducts(null)
            document.querySelector('.search-catalog__autocomplete').classList.add('d-none')
            document.querySelector('.search-catalog__block').classList.remove('search-catalog--active')
        }
    }
    return (
        <>
            <div className="popup-search shown" style={{ zIndex: 301, opacity: 1, display: 'block', top: 0, left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto' }}>
                <div className="popup__content popup-search__content">
                    <div className="popup-search__header">
                        <div className="popup-search__input">
                            <input autoComplete="off" onInput={searchInput} id="mobileSearchInput" type="search" />
                            <button className="popup-search__clear hide" />
                        </div>
                        <button className="popup-search__cancel" onClick={() => context.setSearchPopUp(false)}>Ləğv et</button>
                    </div>
                    <div className="popup-search__hints autocomplete" >
                        <ul className="autocomplete__list autocomplete__list--catalog">
                            {
                                products && products.map((product, index) => (
                                    <li className="autocomplete__item j-suggest" >
                                        <Link to={`/product/${product.slug}`}>
                                            <p className="autocomplete__text">
                                                <span className="autocomplete__icon loupe" />
                                                <span className="autocomplete__phrase">{product.product_name}</span>
                                            </p>
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>


        </>
    )
}
