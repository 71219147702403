import React, { useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import useGetOrders from "../../hooks/useGetOrders";
import {AuthenticationContext} from "../../Context/AuthenticationContext";
import Helmet from "react-helmet";


export default function Orders() {
    const authContext = useContext(AuthenticationContext);
    const {  orders, getOrders } = useGetOrders();

    useEffect(() => {
        getOrders({user_id: authContext.user.id});
    }, []);
    return (
        <>
            <Helmet>
                <title>Mega az onlayn alış veriş mağazası</title>
                <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
                <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
            </Helmet>
            {
                orders ? (
                    <div className="table-responsive shadow">
                        <table className="table table-bordered table-hover mb-0">
                            <thead>
                            <tr>
                                <th>Məbləğ</th>
                                <th>Status</th>
                                <th>Ödəniş metodu</th>
                                <th>Tarix</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                orders.map((order, index) => (
                                    <tr>
                                        <td>
                                            {order.order_amount} ₼
                                        </td>
                                        <td>
                                            {order.status == 'Payment is expected' && <div className="text-warning m-0">Ödəniş gözlənilir</div>}
                                            {order.status == 'Your order has been received' && <div className="text-info m-0">Gözləyən</div>}
                                            {order.status == 'Payment approved' && <div className="text-primary m-0">Təsdiqlənmiş</div>}
                                            {order.status == 'Cargoed' && <div className="text-primary m-0">Kargoda</div>}
                                            {order.status == 'Order completed' && <div className="text-success m-0">Tamamlandı</div>}
                                            {order.status == 'Your order is canceled' && <div className="text-danger m-0">Ləğv edildi</div>}
                                        </td>
                                        <td>{order.bank}</td>
                                        <td>{order.created_at}</td>
                                        <td className="text-end">
                                            <Link to={`/account/order/${order.id}`} className="btn btn-xs btn-primary" style={{ minWidth: 'max-content' }}>Sifarişə bax</Link>
                                            {order.status == 'Payment is expected' && <button className="btn btn-xs btn-success ms-2" style={{ minWidth: 'max-content' }}>Ödənişi tamamla</button>}

                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                ) : <h1 className="text-center">Sifariş yoxdur</h1>
            }
        </>
    )
}
