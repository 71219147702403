import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';


export default class BannerBig extends Component {

    render() {
        return (
            <Fragment>
                <div className="main-page__blocks-wrap">
                    <div className="j-banners-block main-page__promo promo promo--4">
                        <div className="swiper-container">
                            <ul className="swiper-wrapper promo__list m-0 p-0">
                                {
                                    this.props.items ? this.props.items.map(item => 
                                        <li key={item.id} className="swiper-slide promo__item">
                                            <div className="img-plug banners-zones-container" data-plug-text="">
                                                <div className="banners-zones" >
                                                    <Link to={item.banner_slug ? item.banner_slug : '/'} className="j-banner-shown-stat j-banner-click-stat j-banner-wba j-banner" >
                                                        <div className="img-wrap">
                                                            <img src={`${process.env.REACT_APP_URL}assets/img/banners/${item.banner_image}`} alt={item.banner_image} />
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                    ) : null
                                }
                            </ul>
                        </div>

                    </div>
                </div>
            </Fragment>
        )
    }
}
