import React, { useState, useContext, useEffect } from 'react'
import { MegaContext } from '../Context/MegaContext';
import { AuthenticationContext } from "../Context/AuthenticationContext";
import axios from "axios";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";

function NewReview({id}) {
  const megaContext = useContext(MegaContext);
  const authContext = useContext(AuthenticationContext);
  let name = authContext.user && authContext.user.first_name + " " + authContext.user.last_name
  let email = authContext.user && authContext.user.email
  const [data, setData] = useState({id: megaContext.productId, name: name, email: email});

  const handleData = (e) => {
      const { name, value } = e.target;
      let newData = { ...data};
      newData[name] = value;
  
      setData(newData);
    };
    const ratingChanged = (rating) => {
        let newData = { ...data};
      newData['rating'] = rating;
  
      setData(newData);
    };
  const formSubmit = async (e) => {
      e.preventDefault();

      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}/review/send`,
        data
      );

      response = response.data;
      if (response.status === "success") {
        toast.success(response.message);
        megaContext.setNewReview(false, null)
      }
      if (response.status === "warning") {
        let message = response.message;
        for (const value of Object.values(message)) {
          for (let i = 0; i < value.length; i++) {
            toast.warning(value[i]);
          }
        }
      }
      if(response.status === 'error'){
        toast.error(response.message);
      }
      
    };
  return (
      <>
          <div className="i-popup-confirmCode centered shown popup-edit-name" style={{ maxHeight: 'max-content', top: window.scrollY + 20, left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', zIndex: 301, opacity: 1, display: 'block' }}>
              <a href="#" className="j-close popup__close close" onClick={() => megaContext.setNewReview(false)}/>
              <div className="popup__content">
                  <form onSubmit={formSubmit}>
                      <h2 className="popup__header">Rəy yazın</h2>
                      <ul className="form-list p-0">
                          
                          <li className="form-list__item form-block">
                            <input name="name" className='form-control' readOnly={authContext.user ? true : false} value={data.name} placeholder='Ad / Soyad' onChange={handleData}/>
                          </li>
                          <li className="form-list__item form-block">
                            <input name="email" className='form-control' readOnly={authContext.user ? true : false} value={data.email} placeholder='Email' onChange={handleData}/>
                          </li>
                          <li className="form-list__item form-block">
                              <textarea name="review" className="val-msg" rows={10} placeholder="Mətn daxil edin" onChange={handleData}></textarea>
                          </li>
                          <li className="form-list__item form-block">
                            <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={24}
                                activeColor="#ffd700"
                            />
                          </li>
                      </ul>
                      <div className="popup__btn">
                          <button className="btn-main w-100" type="submit" >Göndər</button>
                      </div>
                  </form>
              </div>
          </div>
          <div className="i-overlay initially-hidden j-custom-overlay" style={{ zIndex: 300 }} onClick={() => megaContext.setNewReview(false)}/>
      </>
  )
}

export default NewReview;