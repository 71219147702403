import axios from "axios";


class CategoryRepository {
    async getCategories() {

        const categories = await axios.get(`${process.env.REACT_APP_API_URL}/categories`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return categories;
    }
    async getCategory(params) {

        const category = await axios.post(`${process.env.REACT_APP_API_URL}/category`, params)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return category;
    }
    async getProducts(params) {

        const products = await axios.post(`${process.env.REACT_APP_API_URL}/category/products`, params)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return products;
    }
}


export default new CategoryRepository();