import React, {useContext, useEffect, useState} from "react";
import ReactImageZoom from "react-image-zoom";
import SwiperCore, {
    Navigation,
    Pagination,
    EffectFade,
    A11y,
    Autoplay,
} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {toast} from "react-toastify";
import {MegaContext} from "../Context/MegaContext";
import {CartContext} from "../Context/CartContext";
import {Link} from "react-router-dom";

SwiperCore.use([Navigation, Pagination, A11y, EffectFade, Autoplay]);

export default function QuickView() {
    const context = useContext(MegaContext);
    const contextCart = useContext(CartContext);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);
    const [filteredImages, setFilteredImages] = useState([]);


    if (!filteredImages.length && context.product) {
        setFilteredImages(context.product.images)
    }

    const selectColor = (e, colorId) => {
        setSelectedColor(Number(colorId));
        setSelectedSize(null);
        // setFilteredPrice(
        //   product.price.filter((sizeItem) => sizeItem.color_id === colorId)
        // );
        let images = context.product.images.filter(
            (imageItem) => Number(imageItem.color_id) === colorId
        );
        if (images.length === 0) {
            images = context.product.images;
        }
        document.querySelector(".photo-zoom__preview").src = `${process.env.REACT_APP_URL}assets/img/products/${images[0].main_name}`;
        setFilteredImages(images);
    };
    const selectSize = (e, sizeId) => {
        if (!selectedColor) {
            toast.warning("İlk öncə rəng seçin");
            return false;
        }

        setSelectedSize(Number(sizeId));
    };

    const addToCart = (e) => {

        // if (context.product.colors.filter(item => Number(item.id) !== 1).length === 0) {
        //     setSelectedColor(1)
        // }
        let colors = context.product.price.filter((item) => Number(item.color_id) > 0);
        let sizes = context.product.price.filter((item) => Number(item.size_id) > 0);
        if (colors.length > 0 && !selectedColor) {
            toast.error("Zəhmət olmasa məhsul rəngini seçin");
        } else if (sizes.length > 0 && !selectedSize) {
            toast.error("Zəhmət olmasa məhsul ölçüsünü seçin");
        } else if (
            context.product.price.length === 0 ||
            context.product.price.filter(
                (item) =>
                    Number(item.color_id) === Number(selectedColor) &&
                    Number(item.size_id) === Number(selectedSize)
            ).length === 0 ||
            !(
                context.product.price.find(
                    (item) =>
                        Number(item.color_id) === Number(selectedColor) &&
                        Number(item.size_id) === Number(selectedSize)
                )?.stock_piece > 0
            )
        ) {
            toast.error("Məhsul anbarda mövcud deyil");
        } else if (
            context.product.price.find(
                (item) =>
                    Number(item.color_id) === Number(selectedColor) &&
                    Number(item.size_id) === Number(selectedSize)
            )?.stock_piece < 1
        ) {
            toast.error("Seçilən məhsul sayı anbar sayından çoxdur");
        } else {
            let priceList = context.product.price.filter(
                (item) =>
                    Number(item.color_id) === Number(selectedColor) &&
                    Number(item.size_id) === Number(selectedSize)
            );

            let authentication = JSON.parse(localStorage.getItem("authentication"));
            let cartId = JSON.parse(localStorage.getItem("cart_id"));

            let amount = priceList.length > 0 ? priceList[0].sale_price : 0;
            let priceId = priceList.length > 0 ? priceList[0].id : null;
            contextCart.addToCart({
                piece: 1,
                product_id: context.product.id,
                price_id: priceId,
                amount: amount,
                size_id: selectedSize,
                color_id: selectedColor,
                cart_id: cartId,
                user: authentication ? authentication.user.id : null
            });
        }
    };

    useEffect(() => {
        if(!selectedColor) {
            setSelectedColor(context.product.colors.filter(item => Number(item.id) !== 1).length > 0 ? null : 1)
        }

        function handleResize() {
            if (window.innerWidth < 1024) {
                context.setQuickView(false, null);
            }
        }

        window.addEventListener("resize", handleResize);
    });

    const imageView = (e) => {
        e.preventDefault();
        let src = e.target.getAttribute("data-main-name");

        document.querySelector(".photo-zoom__preview").src = src;
    };
    return (
        <>
            <div
                className="i-popup-same-part-kt shown"
                style={{
                    zIndex: 301,
                    opacity: 1,
                    display: "block",
                    top: window.scrollY,
                    left: 0,
                    right: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    minHeight: "max-content",
                }}
            >
                <span
                    className=" popup__close close pointer"
                    onClick={() => context.setQuickView(false)}
                />

                <div className="content">
                    <div className="same-part-kt">
                        <div className="same-part-kt__slider-wrap j-card-left-wrap">
                            <div className="same-part-kt__sticky-wrap j-card-left">
                                <div className="same-part-kt__slider">
                                    <div className="sw-slider-kt-mix carousel-popup">
                                        <button
                                            className="swiper-button-prev swiper-vertical-button-prev"
                                            type="button"
                                            tabIndex={-1}
                                        >
                                            Əvvəlki slayd
                                        </button>
                                        <div className="sw-slider-kt-mix__wrap">
                                            <Swiper
                                                // modules={[Navigation, Pagination, A11y]}
                                                spaceBetween={10}
                                                slidesPerView={2}
                                                direction="horizontal"
                                                centeredSlides={true}
                                                navigation={{
                                                    nextEl: ".swiper-vertical-button-next",
                                                    prevEl: ".swiper-vertical-button-prev",
                                                }}
                                                className="j-sw-images-carousel"
                                                breakpoints={{
                                                    576: {
                                                        slidesPerView: 2,
                                                        spaceBetween: 10,
                                                        direction: "horizontal",
                                                        centeredSlides: true,
                                                    },
                                                    1024: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 15,
                                                        direction: "vertical",
                                                        centeredSlides: false,
                                                    },
                                                }}
                                            >
                                                {filteredImages.length > 0 &&
                                                    filteredImages.map((item) => (
                                                        <SwiperSlide className="slide">
                                                            <div className="slide__content img-plug">
                                                                <img
                                                                    onClick={(e) => imageView(e)}
                                                                    onMouseOver={(e) => imageView(e)}
                                                                    src={`${process.env.REACT_APP_URL}assets/img/products/${item.image_name}`}
                                                                    alt={item.image_name}
                                                                    data-main-name={`${process.env.REACT_APP_URL}assets/img/products/${item.main_name}`}
                                                                />
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                            </Swiper>
                                            <div className="sw-slider-kt-mix__btn-wrap hide-desktop">
                                                <span className="sw-slider-kt-mix__btn sw-slider-kt-mix__btn--back"/>
                                                <button
                                                    className="sw-slider-kt-mix__btn sw-slider-kt-mix__btn--share"
                                                    type="button"
                                                />
                                                <button
                                                    className="sw-slider-kt-mix__btn sw-slider-kt-mix__btn--to-poned"/>
                                            </div>
                                        </div>
                                        <button
                                            className="swiper-button-next swiper-vertical-button-next"
                                            type="button"
                                            tabIndex={0}
                                        >
                                            Növbəti slayd
                                        </button>
                                    </div>
                                </div>
                                <div className="same-part-kt__mix-block hide-mobile">
                                    <div id="photo" className="mix-block">
                                        <div
                                            id="imageContainer"
                                            className="mix-block__photo-zoom photo-zoom"
                                        >
                                            <div
                                                className="photo-zoom__img-plug img-plug"
                                                style={{opacity: 1}}
                                            >
                                                <img
                                                    className="photo-zoom__preview j-zoom-preview"
                                                    src={
                                                        context.product.images.length > 0
                                                        && `${process.env.REACT_APP_URL}assets/img/products/${context.product.images[0].main_name}`
                                                    }
                                                    alt={
                                                        context.product.images.length > 0
                                                        && context.product.images[0].image_name
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="same-part-kt__header-wrap hide-mobile pb-3">
                            <Link
                                className="same-part-kt__header j-product-title "
                                to={`/product/${context.product.slug}`}
                            >
                                {context.product.product_name}
                            </Link>
                        </div>
                        <div id="infoBlockProductCard" className="same-part-kt__info-wrap">
                            <div className="same-part-kt__price-block">
                                <div className="price-block">
                                    <div className="price-block__content">
                                        <p className="price-block__price-wrap">
                                            <PriceList
                                                price={context.product.price}
                                                discount={context.product.discount}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <ColorList
                                product={context.product}
                                selectedColor={selectedColor}
                                selectColor={selectColor}
                            />
                            <SizeList
                                product={context.product}
                                // filteredPrice={filteredPrice}
                                selectedSize={selectedSize}
                                selectedColor={selectedColor}
                                selectSize={selectSize}
                            />
                            <div className="same-part-kt__order">
                                <div className="same-part-kt__btn-wrap">
                                    <button className="btn-main"
                                            disabled={
                                                context.product.price.length === 0
                                                    ? true
                                                    : false
                                            }
                                            onClick={(e) => addToCart(e)}
                                    >
                                        Səbətə at
                                    </button>
                                </div>
                            </div>
                            <div className="same-part-kt__delivery-advantages">
                                <div className="same-part-kt__logo-wrap hide-mobile">
                                    <div className="same-part-kt__brand-logo">
                                        <Link
                                            className="img-plug"
                                            id="brandBannerImgRef"
                                            title={context.product.brands[0].name}
                                            to={`/brand/${context.product.brands[0].slug}`}
                                        >
                                            {context.product.brands[0].image ? (
                                                <img
                                                    width={120}
                                                    alt={context.product.brands[0].image}
                                                    src={`${process.env.REACT_APP_URL}assets/img/brand/${context.product.brands[0].image}`}
                                                />
                                            ) : (
                                                context.product.brands[0].name
                                            )}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="i-overlay initially-hidden j-custom-overlay"
                style={{zIndex: 300}}
                onClick={() => context.setQuickView(false)}
            />
        </>
    );
}

function PriceList(props) {
    let filtered = props.price.filter((item) => {
        if (item.color_id > 1 && item.size_id != null) {
            return true;
        }
    });
    if (filtered.length == 0) {
        filtered = props.price.filter((item) => {
            if (item.color_id > 1 && item.size_id == null) {
                return true;
            }
        });
    }
    if (filtered.length === 0) {
        filtered = props.price.filter((item) => {
            if (item.color_id == 1 && item.size_id != null) {
                return true;
            }
        });
    }
    if (filtered.length == 0) {
        filtered = props.price.filter((item) => {
            if (item.color_id == 1 && item.size_id == null) {
                return true;
            }
        });
    }

    const discountPrice = (price, discount) => {
        return (price - (price * discount) / 100).toFixed(2);
    };
    if (filtered.length > 0) {
        if (props.discount) {
            return (
                <>
          <span className="price-block__final-price azn">
            {discountPrice(filtered.length > 0 ? filtered[0].sale_price : 0, props.discount)}
          </span>
                    <del className="price-block__old-price j-final-saving azn">
                        {filtered.length > 0 ? filtered[0].sale_price : 0}
                    </del>
                </>
            );
        } else {
            return (
                <>
          <span className="price-block__final-price azn">
            {filtered.length > 0 ? filtered[0].sale_price : 0}
          </span>
                </>
            );
        }
    } else {
        return null;
    }
}

function ColorList({product, selectedColor, selectColor}) {
    if (
        product.price.find((colorItem) => Number(colorItem.color_id) !== 1)
    ) {
        const key = "color_id";
        const uniquePrice = [
            ...new Map(product.price.map((item) => [item[key], item])).values(),
        ];
        return (
            <>
                <button
                    className="same-part-kt__color"
                    style={{display: "block"}}
                >
                    Rənglər
                </button>
                <div className="same-part-kt__slider-color">
                    <div
                        className="d-flex align-items-center"
                        style={{flexWrap: "wrap"}}
                    >
                        {uniquePrice
                            .filter((colorItem) => Number(colorItem.color_id) !== 1)
                            .map((item) => (
                                <label
                                    key={`color-${item.color_id}`}
                                    className={`color-picker-element ${
                                        Number(item.color_id) === selectedColor && "active"
                                    }`}
                                    onClick={(e) =>
                                        selectColor(e, Number(item.color_id))
                                    }
                                    title={
                                        product.colors.find(
                                            (colorItem) =>
                                                colorItem.id === Number(item.color_id)
                                        )?.title
                                    }
                                >
									<span
                                        className="colors-list__color"
                                        style={{
                                            backgroundColor: product.colors.find(
                                                (colorItem) =>
                                                    colorItem.id === Number(item.color_id)
                                            )?.name
                                        }}
                                    ></span>
                                </label>
                            ))}
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
}

function SizeList({product, selectedSize, selectedColor, selectSize}) {
    if (product.price.find((item) => item.size_id != null)) {
        const key = "size_id";
        let priceList = product.price;
        if (selectedColor) {
            priceList = product.price.filter(
                (item) => Number(item.color_id) === selectedColor
            );
        }
        const uniquePrice = [
            ...new Map(priceList.map((item) => [item[key], item])).values(),
        ];
        return (
            <>
                <div className="same-part-kt__table-of-sizes">
                    <button className="same-part-kt__show-sizes" type="button">
                        Ölçü cədvəli
                    </button>
                </div>
                <ul className="same-part-kt__sizes-list sizes-list j-size-list">
                    {uniquePrice.map((item) => (
                        <li
                            key={`size-${item.id}`}
                            className="sizes-list__item"
                        >
                            <label
                                className={`j-size ${
                                    Number(item.size_id) === selectedSize && "active"
                                }`}
                                onClick={(e) => selectSize(e, Number(item.size_id))}
                            >
								<span className="sizes-list__size">
									{
                                        product.sizes.find(
                                            (sizeItem) =>
                                                sizeItem.id === Number(item.size_id)
                                        )?.name
                                    }
								</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </>
        );
    } else {
        return null;
    }
}
