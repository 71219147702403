import React, { Component } from 'react'
import { MegaContext } from '../../Context/MegaContext';
import { Link } from "react-router-dom";

export default class Products extends Component {
    static contextType = MegaContext;
    quickView = (e, item) => {
        e.preventDefault()
        this.context.setQuickView(true, item)
    }
    render() {
        return (
            <>
                <div className="j-products-container-block">
                    {
                        this.props.title ? <h2 className="goods__header">{this.props.title} {this.props.slug ? (
                            <Link className="home__see-all" to={`/${this.props.slug}`}>Hamısı</Link>
                        ) : ''} </h2> : null
                    }

                    <ul className="goods__list p-0 ">
                        {
                            this.props.items ? this.props.items.map( item =>
                                <li key={item.id} className="goods__item goods-card j-product-item" >
                                    <Link to={`/product/${item.slug}`} className="goods-card__container j-open-full-product-card">
                                        <div className="goods-card__img-wrap img-plug" >
                                            <picture className="j-lazy-img">
                                                <img src={item.images.length > 0 ? `${process.env.REACT_APP_URL}assets/img/products/${item.images[0].image_name}` : null} alt={item.images.length > 0 ? item.images[0].image_name : null} />
                                            </picture>
                                            <button className="goods-card__preview-btn j-open-product-popup" type="button" onClick={(e) => this.quickView(e, item)} ><i className="fa fa-search-plus"></i> Bax</button>
                                            {item.discount ? <p className="goods-card__discount discount">{item.discount}</p> : null}
                                        </div>
                                        <div className="goods-card__info">

                                            <p className="goods-card__price j-price-block">
                                                <PriceList price={item.price} discount={item.discount} />
                                            </p>

                                            <p className="goods-card__description">
                                                {item.product_name}
                                            </p>
                                        </div>
                                    </Link>
                                </li>
                            ) : null
                        }

                    </ul>
                </div>
            </>
        )
    }
}


function PriceList(props) {
    let filtered = props.price.filter(item => {
        if(item.color_id > 1 && item.size_id != null){
            return true;
        }
    })
    if(filtered.length == 0){
        filtered = props.price.filter(item => {
            if(item.color_id > 1 && item.size_id == null){
                return true;
            }
        })
    }
    if(filtered.length === 0){
        filtered = props.price.filter(item => {
            if(item.color_id == 1 && item.size_id != null){
                return true;
            }
        })
    }
    if(filtered.length == 0){
        filtered = props.price.filter(item => {
            if(item.color_id == 1 && item.size_id == null){
                return true;
            }
        })
    }

    const discountPrice = (price, discount) => {
        return (price - (price*discount/100)).toFixed(2)
    }
    if(filtered.length > 0){
        if(props.discount){
            return  <><span className="goods-card__price-now azn">{discountPrice(filtered[0].sale_price, props.discount)}</span><span className="goods-card__price-last azn">{filtered[0].sale_price}</span></>
        }
        else{
            return <><span className="goods-card__price-now azn">{filtered[0].sale_price}</span></>
        }
    }
    else{
        return null
    }


}
