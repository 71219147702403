import {useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {MegaContext} from "../Context/MegaContext";

function QuickViewClose() {
    const context = useContext(MegaContext);
    const location = useLocation()
    useEffect(() => {
        context.setQuickView(false)
    }, [location])

    return '';
}

export default QuickViewClose;
