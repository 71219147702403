import React, { useContext, useEffect, useState } from 'react'
import { MegaContext } from '../../Context/MegaContext';
import useGetCategories from '../../hooks/useGetCategories';
import { useParams, Link, useLocation } from "react-router-dom";
import NotFound from "../NoFound";
import Products from "../catalog/Products";
import useGetProducts from "../../hooks/useGetProducts";

function BreadCrumps () {
    return (
        <ul className="breadcrumbs__list">
            <li className="breadcrumbs__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                <Link className="breadcrumbs__link" to="/" >Ana Səhifə</Link>
            </li>
            <li className="breadcrumbs__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                <span itemProp="name">Endirimli məhsullar</span>
            </li>
        </ul>
    )
}

function changePage (p, pagination, getProducts) {
    if (p < 1) {
        p = 1
    } else if (p > pagination) {
        p = pagination
    }
    getProducts({page: p})
}
function CreatePagination ({pagination, page, getProducts}) {
    let p = []
    if (pagination > 1) {
        p.push(<span className="pagination-prev pagination-item pagination__prev" onClick={() => changePage(page - 1, pagination, getProducts)}><i className="fa fa-chevron-left"></i></span>)
        for (let i = 1; i <= pagination; i++) {
            p.push(<span className={`pagination-item pagination__item ${page === i ? 'active' : ''}`} onClick={() => changePage(i, pagination, getProducts)} >{i}</span>)
        }
        p.push(<span className="pagination-next pagination-item pagination__next" onClick={() => changePage(page + 1, pagination, getProducts)}><i className="fa fa-chevron-right"></i></span>)
    }
    return p;
}

export default function Discounts() {

    const { discountItems, count, pagination, page, error, loading, setLoading, getDiscounts } = useGetProducts();
    const context = useContext(MegaContext);
    const location = useLocation()

    const quickView = (e, item) => {
        e.preventDefault()
        context.setQuickView(true, item)
    }
    const setIsShown = (e) => {
        e.target.closest('.product-card').classList.add('hover')
    }
    const setIsHide = (e) => {
        e.target.closest('.product-card').classList.remove('hover')
    }

    useEffect(() => {
        getDiscounts({page: 1})
    }, [location]);

    if(error){
        return <NotFound />
    }
    else{
        if(discountItems){
            return (
                <>
                    <div id="catalog" className="catalog-page catalog-page--brand">
                        <div className="catalog-page__breadcrumbs">
                            <div >
                                <div className="breadcrumbs">
                                    <div className="breadcrumbs__container">
                                        <BreadCrumps />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="brand-custom-header">
                            <div id="name" className="brand-custom-header__title">
                                <h1 className="brand-custom-header__name" >Endirimli məhsullar</h1>
                                <span className="brand-custom-header__count" >
                                    <span>{count} </span> <span>məhsul</span>
                                </span>
                            </div>
                        </div>


                        {/*<div className="catalog-page__side">*/}

                        {/*</div>*/}

                        <div className="catalog-page__main common-brand-catalog new-size"  style={{ width: '100%'}}>
                            <div className>
                                <div className="catalog-page__content" id="catalog-content" >
                                    <div className="product-card-overflow">
                                        <div className="product-card-list">

                                            {
                                                loading ?
                                                    <div class="general-preloader mx-auto"></div> :
                                                    <>
                                                        <Products products={discountItems} setIsShown={setIsShown} setIsHide={setIsHide} quickView={quickView} />
                                                        <span className="product-card product-card--fake"></span>
                                                        <span className="product-card product-card--fake"></span>
                                                        <span className="product-card product-card--fake"></span>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="pager-bottom">
                                    <div className="pager i-pager pagination" >
                                        <div className="pageToInsert pagination__wrapper" >

                                            <CreatePagination pagination={pagination} page={page} getProducts={getDiscounts} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else{
            return <div class="general-preloader"></div>
        }
    }

}
