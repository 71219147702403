import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination, EffectFade, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, A11y, EffectFade, Autoplay]);

export default class Slider extends Component {

    render() {
        return (
            <Fragment>
                <div className="main-page__banner banner">
                    <div className="banner__content j-big-banners-block" >
                        <Swiper
                            // modules={[Navigation, Pagination, A11y]}
                            spaceBetween={50}
                            slidesPerView={1}
                            loop={true}
                            autoplay={{ delay: 5000 }}
                            effect="fade"

                            fadeEffect={{
                                crossFade: true // enables slides to cross fade
                            }}
                            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                            className='j-main-banners'
                            pagination={{
                                clickable: true,
                                el: `.swiper-pagination`,
                                bulletClass: `swiper-pagination-bullet`
                            }}
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log('slide change')}
                        >

                            {
                                this.props.items ? this.props.items.map(item =>
                                    <SwiperSlide key={item.id}>
                                        <div className="banners-catalog-custom__zones" >
                                            <Link to={item.slider_slug ? item.slider_slug : '/'} className="j-banner-shown-stat j-banner-click-stat j-banner-wba j-banner"  >
                                                <div className="banners-catalog-custom__img">
                                                    <img src={`${process.env.REACT_APP_URL}assets/img/sliders/${item.slider_image}`} alt={item.slider_image} />
                                                </div>
                                            </Link>
                                        </div>
                                    </SwiperSlide>
                                ) : null
                            }
                            <div className="swiper-button-prev"></div>
                            <div className="swiper-button-next"></div>
                            <ul className="swiper-pagination j-main-banners-pagination swiper-pagination-clickable swiper-pagination-bullets-dynamic swiper-pagination-bullets" style={{ width: 160 }}>
                            </ul>
                        </Swiper>
                    </div>
                </div>
            </Fragment>
        )
    }
}
