import React, { useEffect } from 'react'
// eslint-disable-next-line
import { MegaContext } from '../../Context/MegaContext';
import BannerOne from './BannerOne';
import BannerTwo from './BannerTwo';
import BannerBig from './BannerBig';
import Slider from './Slider';
import Products from './Products';
// import Skeleton from 'react-loading-skeleton'


// import useGetCategories from '../hooks/useGetCategories';
import useGetSliders from '../../hooks/useGetSliders';
import useGetBanners from '../../hooks/useGetBanners';
import useGetProducts from '../../hooks/useGetProducts';
import Helmet from "react-helmet";


export default function Home() {
    const {  discountItems, bestSellingItems, lastViewItems, getProducts } = useGetProducts();
    const { slidersItems, getSliders } = useGetSliders();
    const { bigBannerItems,  oneBannerItems, twoBannerItems, getBanners } = useGetBanners();
    useEffect(() => {
        getSliders();
        getBanners();
        getProducts();
    }, []);
    return (
        <React.Fragment>
            <Helmet>
                <title>Mega az onlayn alış veriş mağazası</title>
                <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
                <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
            </Helmet>

            <div className="main-page">
                <div className="loader"></div>

                <Slider items={slidersItems} />
                <BannerBig items={bigBannerItems} />

                 {/*<Skeleton />*/}

                { discountItems ? <Products title={"Endirimli məhsullar"} items={discountItems} slug="discounts" /> : null }

                <BannerOne items={oneBannerItems} />

                { bestSellingItems ? <Products title={"Ən çox satılanlar"} items={bestSellingItems} slug="best-selling"/> : null }

                <BannerTwo items={twoBannerItems} />

                { lastViewItems ? <Products title={"Son baxılanlar"} items={lastViewItems} /> : null }


            </div>


        </React.Fragment>
    )
}
