import React, { Component } from 'react'

// import { createBrowserHistory } from "history";
export const MegaContext = React.createContext();
MegaContext.displayName = 'MegaContext';


class MegaContextProvider extends Component {
    constructor (props){
        super(props);
        this.state = {
            loading: true,
            menuBurger: false,
            quickView: false,
            product: null,
            searchPopUp: false,
            cropImagePopUp: false,
            popUpLogin: false,
            namePopUp: false,
            emailPopUp: false,
            mobilePopUp: false,
            addressPopUp: false,
            passwordPopUp: false,
            newReview: false,
            productId: 0
        };
    }
    setLoading = (value) => {
        this.setState({loading: value})
    }
    setMenuBurger = (value) => {
        this.setState({menuBurger: value})
        if(value){
            document.querySelector('body').classList.add('body--overflow')
        }
        else{
            document.querySelector('body').classList.remove('body--overflow')
        }

    }
    setQuickView = (value, product) => {
        this.setState({quickView: value, product: product})
    }
    setSearchPopUp = (value) => {
        this.setState({searchPopUp: value})
    }
    setNamePopUp = (value) => {
        this.setState({namePopUp: value})
    }
    setPopUpLogin = (value) => {
        this.setState({popUpLogin: value})
    }
    setEmailPopUp = (value) => {
        this.setState({emailPopUp: value})
    }
    setMobilePopUp = (value) => {
        this.setState({mobilePopUp: value})
    }
    setAddressPopUp = (value) => {
        this.setState({addressPopUp: value})
    }
    setCropImagePopUp = (value) => {
        this.setState({cropImagePopUp: value})
    }
    setPasswordPopUp = (value) => {
        this.setState({passwordPopUp: value})
    }
    setNewReview = (value, id) => {
        this.setState({newReview: value, productId: id})
    }
    render() {
        return (
            <MegaContext.Provider value={{
                ...this.state,
                setLoading: this.setLoading,
                setMenuBurger: this.setMenuBurger,
                setQuickView: this.setQuickView,
                setSearchPopUp: this.setSearchPopUp,
                setNamePopUp: this.setNamePopUp,
                setEmailPopUp: this.setEmailPopUp,
                setMobilePopUp: this.setMobilePopUp,
                setAddressPopUp: this.setAddressPopUp,
                setCropImagePopUp: this.setCropImagePopUp,
                setPasswordPopUp: this.setPasswordPopUp,
                setNewReview: this.setNewReview,
                setPopUpLogin: this.setPopUpLogin,
                }}>
                {this.props.children}
            </MegaContext.Provider>
        )
    }
}

export default MegaContextProvider;
