import React from 'react'
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

export default function NoFound() {
    return (
        <>
            <Helmet>
                <title>Mega az onlayn alış veriş mağazası</title>
                <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
                <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
            </Helmet>

            <div className="content404" id="errorPage">
                <h1 className="content404__title">Sorğunuz üçün heç nə tapılmadı</h1>
                <Link to="/" className="btn-main j-transition-block">Ana səhifəyə get</Link>
            </div>
        </>
    )
}
