import React, { Component } from "react";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import serialize from "form-serialize";
import { toast } from "react-toastify";
import { AuthenticationContext } from "../../Context/AuthenticationContext";
import InputMask from 'react-input-mask';
import Helmet from "react-helmet";

class Register extends Component {
  static contextType = AuthenticationContext;
  register = async (event) => {
    event.preventDefault();
    // console.log(this.context)
    // return false

    var data = serialize(event.target, { hash: true });
    let cartId = JSON.parse(localStorage.getItem("cart_id"));
    data['cartId'] = cartId

    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/register`,
      data
    );

    response = response.data;

    console.log(response)

    if (response.status === "success") {
      toast.success(response.message);
      localStorage.setItem(
        "authentication",
        JSON.stringify(response.authentication)
      );
      localStorage.setItem('cart_id', JSON.stringify(response.cart_id))
      this.context.authLogin(response.authentication);
      window.location.href = "/";
    }
    if (response.status === "warning") {
      let message = response.message;
      for (const value of Object.values(message)) {
        for (let i = 0; i < value.length; i++) {
          toast.warning(value[i]);
        }
      }
    }
    if (response.status === "error") {
      toast.error(response.message);
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Mega az onlayn alış veriş mağazası</title>
          <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
          <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
        </Helmet>
        <div className="sign-in-page">
          <div className="sign-in-page__wrap">
            <form id="spaAuthForm" onSubmit={this.register} >
              <h2 className="sign-in-page__title" > Hasab yaradın </h2>
              <div className="sign-in-page__login login">
                <div className="login__phone form-block form-block--phone-dropdown" >
                  <label className="form-block__label">
                    <span className="form-block__name">Ad</span>
                  </label>
                  <div className="form-block__dropdown-wrap">
                    <div className="form-block__input">
                      <input className="input-item" type="text" name="first_name" />
                    </div>
                  </div>
                </div>
                <div className="login__phone form-block form-block--phone-dropdown" >
                  <label className="form-block__label">
                    <span className="form-block__name">Soyad</span>
                  </label>
                  <div className="form-block__dropdown-wrap">
                    <div className="form-block__input">
                      <input className="input-item" type="text" name="last_name" />
                    </div>
                  </div>
                </div>
                <div className="login__phone form-block form-block--phone-dropdown" >
                  <label className="form-block__label">
                    <span className="form-block__name">Email</span>
                  </label>
                  <div className="form-block__dropdown-wrap">
                    <div className="form-block__input">
                      <input className="input-item" type="email" name="email" />
                    </div>
                  </div>
                </div>
                <div className="login__phone form-block form-block--phone-dropdown" >
                  <label className="form-block__label">
                    <span className="form-block__name">Mobil</span>
                  </label>
                  <div className="form-block__dropdown-wrap">
                    <div className="form-block__input">
                      {/*<InputMask mask="+\9\94 (__) ___ __ __" maskChar={null} className="input-item" type="text" name="mobile"/>*/}
                      <input className="input-item" type="text" name="mobile" />
                    </div>
                  </div>
                </div>
                <div className="login__phone form-block form-block--phone-dropdown">
                  <label className="form-block__label">
                    <span className="form-block__name">Şifrə</span>
                  </label>
                  <div className="form-block__dropdown-wrap">
                    <div className="form-block__input">
                      <input className="input-item" type="password" name="password" />
                    </div>
                  </div>
                </div>

                <button
                  id="requestCode"
                  className="login__btn btn-main-lg"
                  type="submit"
                >
                  Hesab Yarat
                </button>
              </div>
              <div className="sign-in-page__public p-0">
                <Link to="/auth/login">
                  <PersonIcon />{" "}
                  <span style={{ verticalAlign: "middle" }}>
                    Hesabınız var?
                  </span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Register;
