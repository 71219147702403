import { useState } from 'react'

import ProductsRepository from '../repositories/ProductsRepository';

export default  function useGetProducts() {

    const [discountItems, setDiscountItems] = useState(null);
    const [wishListItems, setWishListItems] = useState(null);
    const [bestSellingItems, setBestSellingItems] = useState(null);
    const [lastViewItems, setLastViewItems] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState(null);
    const [product, setProduct] = useState(null);
    // eslint-disable-next-line
    const [error, setError] = useState(false);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState(1);

    return {
        discountItems,
        bestSellingItems,
        lastViewItems,
        relatedProducts,
        product,
        wishListItems,
        count,
        page,
        pagination,

        getProducts: async () => {
            let recently = sessionStorage.getItem('recently_viewed') ? JSON.parse(sessionStorage.getItem('recently_viewed')) : null;

            let responseData = await ProductsRepository.getProducts(recently);

            if (responseData) {
                setDiscountItems(responseData.discount.length ? responseData.discount : null);
                setBestSellingItems(responseData.bestSelling.length ? responseData.bestSelling : null);
                setLastViewItems(responseData.lastView.length ? responseData.lastView : null);

                setPagination(responseData.pagination);
                setCount(responseData.count);
                setPage(responseData.page);
            }
        },
        getDiscounts: async (payload) => {
            let responseData = await ProductsRepository.getDiscounts(payload);

            if (responseData) {
                setDiscountItems(responseData.discount.length ? responseData.discount : null);

                setPagination(responseData.pagination);
                setCount(responseData.count);
                setPage(responseData.page);
            }
        },
        getBestSelling: async (payload) => {
            let responseData = await ProductsRepository.getBestSelling(payload);

            if (responseData) {
                setBestSellingItems(responseData.bestSelling.length ? responseData.bestSelling : null);

                setPagination(responseData.pagination);
                setCount(responseData.count);
                setPage(responseData.page);
            }
        },
        getWishList: async () => {
            let responseData = await ProductsRepository.getWishList();

            if (responseData) {
                setWishListItems(responseData.products.length ? responseData.products : null);
            }
        },
        getProduct: async (payload, setFilteredImages, setSelectedColor) => {
            setProduct(null)

            let responseData = await ProductsRepository.getProduct(payload);

            if (responseData) {
                if(responseData.status === 'success'){

                    setProduct(responseData.data ? responseData.data : null);
                    setRelatedProducts(responseData.related.length > 0 ? responseData.related : null);
                    // setFilteredPrice(responseData.data.price)
                    setFilteredImages(responseData.data.images)
                    setSelectedColor(responseData.data.colors.filter(item => Number(item.id) !== 1).length > 0 ? null : 1)
                }
                else{
                    setError(true)
                }

            }
        }
    }
}

