import React, { useEffect } from "react";
// import CountUp from 'react-countup';
import useGetInfo from "../hooks/useGetInfo";
import { Markup } from 'interweave';
import Helmet from "react-helmet";

export default function About() {
  const { aboutItems, getAbout } = useGetInfo();
  useEffect(() => {
    getAbout();
  }, []);
  return (
    <>
      <Helmet>
        <title>Mega az onlayn alış veriş mağazası</title>
        <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
        <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
      </Helmet>

      <div className="service-page">
        <div className="service-page__content j-service-page-content">
          <div className="service-page-iframe" id="container">
            <div className="about-us page-wrap">
              <h1 className="c-h1">Şirkət haqqında</h1>
              <section className="start-block">
                <div className="intro">
                  <div className="text">
                    <h2 className="c-h1">Mega-ya xoş gəlmisiniz</h2>
                  </div>
                  <div className="banner">
                    <div className="wrap">
                      <canvas id="bubble" width={1440} height={448} />
                    </div>
                  </div>
                </div>
              </section>
              <section className="features text-justify">
              <Markup content={aboutItems ? aboutItems.about :  null} />
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
