import { useState } from 'react'

import BannerRepository from '../repositories/BannerRepository';

export default  function useGetBanners() {

    const [bigBannerItems, setBigBannerItems] = useState(null);
    const [oneBannerItems, setOneBannerItems] = useState(null);
    const [twoBannerItems, setTwoBannerItems] = useState(null);


    return {
        bigBannerItems,
        oneBannerItems,
        twoBannerItems,

        getBanners: async () => {
            let responseData = await BannerRepository.getBanners();

            if (responseData) {
                setBigBannerItems(responseData.banner_big.length ? responseData.banner_big : null);
                setOneBannerItems(responseData.banner_one ? responseData.banner_one : null);
                setTwoBannerItems(responseData.banner_two ? responseData.banner_two : null);
            }
        }
    }
}

