import {useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {MegaContext} from "../Context/MegaContext";

function MenuClose() {
    const context = useContext(MegaContext);
    const location = useLocation()
    useEffect(() => {
        return () => {
            context.setMenuBurger(false)
        }
    }, [location])

    return (null);
}

export default MenuClose;
