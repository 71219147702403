import React from "react";
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import Category from "../Components/Category";
import Panel from "../Components/Panel";
import QuickView from "../Components/QuickView";
import {AuthenticationContext} from "../Context/AuthenticationContext";
import {MegaContext} from "../Context/MegaContext";
import {CartContext} from "../Context/CartContext";
import MegaContextProvider from "../Context/MegaContext";
import AuthenticationContextProvider from "../Context/AuthenticationContext";
import CartContextProvider from "../Context/CartContext";

import SearchPopUp from "../Components/SearchPopUp";
import Menu from "../Pages/account/Menu";

import PopUpName from "../Components/PopUpName";
import PopUpLogin from "../Components/PopUpLogin";
import PopUpEmail from "../Components/PopUpEmail";
import PopUpAddress from "../Components/PopUpAddress";
import PopUpMobile from "../Components/PopUpMobile";
import PopUpCropImage from "../Components/PopUpCropImage";
import ScrollToTop from "../Components/ScrollToTop";
import {ToastContainer} from "react-toastify";
import PopUpPassword from "../Components/PopUpPassword";
import NewReview from "../Components/NewReview";
import MenuClose from "../Components/MenuClose";
import AppRoutes from "../AppRoutes";
import QuickViewClose from "../Components/QuickViewClose";

export class App extends React.Component {

    constructor() {
        super();
    }
    static contextType = MegaContext;

    render() {
        return (
            <AuthenticationContextProvider>
                <CartContextProvider>
                    <MegaContextProvider>
                        <AuthenticationContext.Consumer>
                            {(contextAuth) => (
                                <CartContext.Consumer>
                                    {(contextCart) => (
                                        <MegaContext.Consumer>
                                            {(contextMega) => {
                                                return (
                                                    <React.Fragment>
                                                            <ScrollToTop/>
                                                            <MenuClose/>
                                                            <QuickViewClose />

                                                            <ToastContainer/>
                                                            {contextMega.popUpLogin && <PopUpLogin/>}
                                                            {contextMega.quickView && <QuickView/>}
                                                            {contextMega.searchPopUp && <SearchPopUp/>}
                                                            {contextMega.namePopUp && <PopUpName/>}
                                                            {contextMega.emailPopUp && <PopUpEmail/>}
                                                            {contextMega.mobilePopUp && <PopUpMobile/>}
                                                            {contextMega.addressPopUp && <PopUpAddress/>}
                                                            {contextMega.cropImagePopUp && <PopUpCropImage/>}
                                                            {contextMega.passwordPopUp && <PopUpPassword/>}
                                                            {contextMega.newReview && <NewReview/>}

                                                            <div className="wrapper">
                                                                <Header/>
                                                                <Category/>
                                                                <main
                                                                    className="main left-bg"
                                                                    role="main"
                                                                    id="body-layout"
                                                                >
                                                                    <Menu/>
                                                                    <div id="mainContainer" className="main__container">
                                                                        <div id="app">
                                                                            <AppRoutes contextAuth={contextAuth} />
                                                                        </div>
                                                                    </div>
                                                                </main>
                                                                <Footer/>
                                                            </div>
                                                            <Panel/>
                                                    </React.Fragment>
                                                );
                                            }}
                                        </MegaContext.Consumer>
                                    )}
                                </CartContext.Consumer>
                            )}
                        </AuthenticationContext.Consumer>
                    </MegaContextProvider>
                </CartContextProvider>
            </AuthenticationContextProvider>
        );
    }
}

