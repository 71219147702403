import React, { useContext } from 'react'
import { MegaContext } from '../Context/MegaContext';
import { CartContext } from "../Context/CartContext";
import { AuthenticationContext } from "../Context/AuthenticationContext";
import {
    useResolvedPath,
    Link
} from "react-router-dom";

export default function Panel() {
    const context = useContext(MegaContext);
    const contextCart = useContext(CartContext);
    const contextAuth = useContext(AuthenticationContext);
    let resolved = useResolvedPath(window.location.pathname);
    return (
        <>
                <div className="navbar-mobile hide-desktop j-navbar-mobile">
                    <Link className="navbar-mobile__link"  to="/" >
                        <span className={`navbar-mobile__icon navbar-mobile__icon--home ${window.location.pathname === '/' && !context.menuBurger ? 'navbar-mobile__icon--active' : ''}`} onClick={() => context.setMenuBurger(false)}/>
                    </Link>
                    <span id="catalogNavbarLink" className="navbar-mobile__link" >
                        <span className={`navbar-mobile__icon navbar-mobile__icon--catalog ${context.menuBurger ? 'navbar-mobile__icon--active' : ''}`} onClick={() => context.setMenuBurger(!context.menuBurger)} />
                    </span>
                    <Link className="navbar-mobile__link" to="/cart" >
                        <span className={`navbar-mobile__icon navbar-mobile__icon--basket ${window.location.pathname === '/cart'  && !context.menuBurger ? 'navbar-mobile__icon--active' : ''}`} onClick={() => context.setMenuBurger(false)}>
                            { contextCart.count ? <span className="navbar-mobile__notify"> {contextCart.count} </span> : null}
                        </span>
                    </Link>
                    <Link className="navbar-mobile__link" to="/account/wishlist" >
                        <span className={`navbar-mobile__icon navbar-mobile__icon--favorites ${window.location.pathname === '/account/wishlist'  && !context.menuBurger ? 'navbar-mobile__icon--active' : ''}`} onClick={() => context.setMenuBurger(false)}/>
                    </Link>
                    <Link className="navbar-mobile__link" to="/account" >
                        <span className={`navbar-mobile__icon navbar-mobile__icon--profile ${window.location.pathname === '/account'  && !context.menuBurger ? 'navbar-mobile__icon--active' : ''}`} onClick={() => context.setMenuBurger(false)}></span>
                    </Link>
                </div>
            </>
    )
}

