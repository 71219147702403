import React, { useEffect } from "react";
import useGetInfo from "../hooks/useGetInfo";

import { Link } from "react-router-dom";

export default function Footer() {
  const showHideHandler = (e) => {
    e.target.closest(".footer__list-wrap").classList.toggle("dropdown-open");
  };
  const { infoItems, getInfo } = useGetInfo();
  useEffect(() => {
    getInfo();
  }, []);
  return (
    <>
      <footer
        className="footer j-footer footer-ru"
        id="footer"
        data-link=""
        data-jsv=""
      >
        <div className="footer__container">
          <div className="footer__nav">
            <div className="footer__service-wrap j-dropdown-footer">
              <div className="footer__list-wrap footer__list-wrap--buyers ">
                <div
                  className="footer__header-wrap j-dropdown-title"
                  onClick={(e) => showHideHandler(e)}
                >
                  <h3 className="footer__header">Mega</h3>
                  <button className="footer__btn-open" type="button"></button>
                </div>
                <ul className="footer__list footer__list--dropdown p-0 m-0">
                  <li className="footer__item">
                    <Link to="/brands">Brendlər</Link>
                  </li>
                  <li className="footer__item">
                    <Link to="/privacy">Məxfilik siyasəti</Link>
                  </li>
                  <li className="footer__item">
                    <Link to="/shipping_return">Çatdırılma vә ödəniş</Link>
                  </li>
                </ul>
              </div>
              <div className="footer__inner-wrap">
                <div className="footer__list-wrap">
                  <div
                    className="footer__header-wrap j-dropdown-title"
                    onClick={(e) => showHideHandler(e)}
                  >
                    <h3 className="footer__header">Şirkət</h3>
                    <button className="footer__btn-open" type="button"></button>
                  </div>
                  <ul className="footer__list footer__list--dropdown p-0 m-0">
                    <li className="footer__item">
                      <Link to="/about">Haqqımızda</Link>
                    </li>
                    <li className="footer__item">
                      <Link to="/contact">Əlaqə</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer__list-wrap footer__list-wrap--company">
                <div
                  className="footer__header-wrap j-dropdown-title"
                  onClick={(e) => showHideHandler(e)}
                >
                  <h3 className="footer__header">Şәxsi kabinet</h3>
                  <button className="footer__btn-open" type="button"></button>
                </div>
                <ul className="footer__list footer__list--dropdown p-0 m-0">
                  <li className="footer__item">
                    <Link to="/account">Hesabım</Link>
                  </li>
                  <li className="footer__item">
                    <Link to="/account/wishlist">İstəklərim</Link>
                  </li>
                  <li className="footer__item">
                    <Link to="/account/orders">Sifarişlərim</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer__social-wrap">
              <div className="footer__list-wrap footer__list-wrap--download">
                <h3 className="footer__header">Ünvan</h3>
                <ul className="footer__list p-0 m-0">
                  <li className="footer__item">
                    <p>{infoItems ? infoItems.address : null}</p>
                  </li>
                </ul>
              </div>
              <div className="footer__list-wrap footer__list-wrap--social">
                <h3 className="footer__header">Sosial şəbəkələrdəyik</h3>
                <ul className="footer__list p-0 m-0">
                  {infoItems && infoItems.facebook ? (
                    <li className="footer__item">
                      <a
                        className="icon-fb"
                        rel="noreferrer"
                        target="_blank"
                        href={infoItems.facebook}
                      >
                        Facebook
                      </a>
                    </li>
                  ) : null}
                  {infoItems && infoItems.instagram ? (
                    <li className="footer__item">
                      <a
                        className="icon-inst"
                        rel="noreferrer"
                        target="_blank"
                        href={infoItems.instagram}
                      >
                        Instagram
                      </a>
                    </li>
                  ) : null}
                  {infoItems && infoItems.twitter ? (
                    <li className="footer__item">
                      <a
                        className="icon-tw"
                        rel="noreferrer"
                        target="_blank"
                        href={infoItems.twitter}
                      >
                        Twitter
                      </a>
                    </li>
                  ) : null}
                  {infoItems && infoItems.youtube ? (
                    <li className="footer__item">
                      <a
                        className="icon-yt"
                        rel="noreferrer"
                        target="_blank"
                        href={infoItems.youtube}
                      >
                        Youtube
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>

          <div className="footer__add-info">
            <ul className="footer__pay-list pay-list p-0 m-0">
              <li className="pay-list__item pay-list__item--visa"></li>
              <li className="pay-list__item pay-list__item--masterCard"></li>
            </ul>
            <p className="footer__copyrights">
              <span>2021 © Mega </span>
              <span className="footer__text-desktop"> —geyim, ayaqqabı və aksesuarların dəbli onlayn mağazası. Bütün hüquqlar qorunur. </span>
              <span className="footer__text-desktop"> Azərbaycan daxilində çatdırılma. </span>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
