import React, { useEffect, useContext, useState } from 'react'
import { MegaContext } from "../Context/MegaContext";
import {
    Link
} from "react-router-dom";

import useGetCategories from '../hooks/useGetCategories';

import noImage from "../assets/images/no-image.svg";



export default function Category(props) {
    const { categoryItems, getCategories } = useGetCategories();
    const context = useContext(MegaContext);
    const [burgerDrop, setBurgerDrop] = useState(null);
    const handlerMenuOver = (item) => {
        const { innerWidth: width } = window;
        if (width >= 1024) {
            setBurgerDrop(item)
        }
    }
    const handlerMenuClick = (item) => {
        const { innerWidth: width } = window;
        if (width < 1024) {
            setBurgerDrop(item)
        }
    }
    const handlerMenuClose = () => {
        setBurgerDrop(null)
        context.setMenuBurger()
    }
    useEffect(() => {
        getCategories();
    }, []);

    return (
        <>
            {context.menuBurger ? <div className="overlay overlay--menu-burger j-overlay" onClick={() => context.setMenuBurger(false)} ></div> : null}
            <div className={`menu-burger j-menu-burger ${context.menuBurger ? 'menu-burger--active' : null}`}>
                <div className="menu-burger__main j-menu-burger-main j-menu-active">

                    <ul className="menu-burger__main-list">

                        {
                            categoryItems ? categoryItems.map(item => {
                                if (item.top_id === null) {
                                    return (
                                        <li key={item.id} className="menu-burger__main-list-item j-menu-main-item menu-burger__main-list-item--subcategory" onMouseOver={() => handlerMenuOver(item)} onClick={() => handlerMenuClick(item)}>
                                            <a href='' onClick={(e) => e.preventDefault()} rel='noreferrer' className="menu-burger__main-list-link">
                                                <img style={{ width: '24px', height: '24px' }} src={item.category_image ? `${process.env.REACT_APP_URL}assets/img/category/${item.category_image}` : noImage} alt={item.category_image} /> {item.category_name}
                                            </a>
                                        </li>
                                    );
                                }
                            }) : null
                        }

                    </ul>

                </div>

                <div className={`menu-burger__drop j-menu-burger-drop ${context.menuBurger ? burgerDrop ? ' menu-burger__drop--active j-menu-active' : null : null}`}>

                    <div className="menu-burger__drop-list j-menu-drop-list">

                        <div className="menu-burger__drop-list-item j-menu-drop-item j-menu-drop-item-258 menu-burger__drop-list-item--active">
                            <ul className="menu-burger__mobile">
                                <li className="menu-burger__mobile-item j-menu-burger-mobile-back" onClick={() => setBurgerDrop(null)}>
                                    <span className="menu-burger__mobile-link menu-burger__mobile-link--back">Əsas menyu</span>
                                </li>
                                <li className="menu-burger__mobile-item">
                                    <Link to={`/catalog/${burgerDrop ? burgerDrop.slug : null}`} className="menu-burger__mobile-link menu-burger__mobile-link--main j-menu-drop-link j-menu-selected-category">
                                        {burgerDrop ? burgerDrop.category_name : null}
                                    </Link>
                                </li>
                            </ul>
                            <ul className="menu-burger__category">
                                <li className="menu-burger__category-item menu-burger__category-item--subcategory j-menu-drop-list-item-subcategory">
                                    <Link to={`/catalog/${burgerDrop ? burgerDrop.slug : null}`} className="menu-burger__category-link j-menu-drop-link j-menu-drop-link-subcategory">
                                        {burgerDrop ? burgerDrop.category_name : null}
                                    </Link>
                                    <ul className="menu-burger__subcategory p-0 m-0" >
                                        {
                                            categoryItems && burgerDrop ? categoryItems.filter(alt => alt.top_id == burgerDrop.id).map(alt => {
                                                return (
                                                        <li key={alt.id} className="menu-burger__subcategory-item">
                                                            <Link to={`/catalog/${alt.slug}`} className="menu-burger__subcategory-link j-menu-drop-link" >
                                                                {alt.category_name}
                                                            </Link>
                                                            <ul className='className="menu-burger__subcategory-item"'>
                                                                {
                                                                    categoryItems && burgerDrop ? categoryItems.filter(item => item.top_id == alt.id).map(second =>
                                                                        <li key={second.id} className="menu-burger__subcategory-item">
                                                                            <Link to={`/catalog/${second.slug}`} className="menu-burger__subcategory-link j-menu-drop-link" >
                                                                                {second.category_name}
                                                                            </Link>
                                                                        </li>
                                                                    ) : null
                                                                }

                                                            </ul>
                                                        </li>
                                                    )
                                            }) : null
                                        }

                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <button className="menu-burger__close j-close-menu" type="button" onClick={() => handlerMenuClose()}>Bağla</button>

            </div>

        </>
    )
}
