import React, {Component} from "react";
import axios from "axios";
import {toast} from "react-toastify";

export const CartContext = React.createContext();
CartContext.displayName = "CartContext";

class CartContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cart: null,
            cartId: null,
            total: null,
            count: null
        };
    }

    componentDidMount = (event) => {
        if (localStorage.hasOwnProperty("cart_id")) {
            this.getCart();
        }
    };
    addToCart = async (data) => {
        let response = await axios.post(
            `${process.env.REACT_APP_API_URL}/cart`,
            data
        );

        response = response.data;

        if (response.cart_id) {
            let cartId = response.cart_id;
            localStorage.setItem("cart_id", cartId);
            this.setState({cartId: cartId});
        }

        if (response.status === "success") {
            toast.success(response.message);
            this.setState({
                cart: response.content,
                total: response.total,
                count: response.cart_count
            });
        } else if (response.status === "error") {
            toast.error(response.message);
        } else {
            console.log(response);
        }
    };
    getCart = async () => {
        let cartId = JSON.parse(localStorage.getItem("cart_id"));
        let response = await axios.get(
            `${process.env.REACT_APP_API_URL}/cart/${cartId}`
        );
        response = response.data;
        this.setState({
            cart: response.content,
            total: response.total,
            count: response.cart_count
        });
    };
    deleteCart = async (id) => {
        let response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/cart/${id}`);
        response = response.data;
        if (response.status == 'success') {
            this.setState({
                cart: response.content,
                total: response.total,
                count: response.cart_count
            });
        }
    };
    updateCart = async (data) => {
        let response = await axios.put(
            `${process.env.REACT_APP_API_URL}/cart/${data.cart_id}`,
            data
        );
        response = response.data;
        if (response.status == 'success') {
            this.setState({
                cart: response.content,
                total: response.total,
                count: response.cart_count
            });
        }

    };
    setCartId = (cart_id) => {
        this.setState({cartId: cart_id})
    }

    render() {
        return (
            <CartContext.Provider
                value={{
                    ...this.state,
                    setCartId: this.setCartId,
                    addToCart: this.addToCart,
                    getCart: this.getCart,
                    deleteCart: this.deleteCart,
                    updateCart: this.updateCart,
                }}
            >
                {this.props.children}
            </CartContext.Provider>
        );
    }
}

export default CartContextProvider;
