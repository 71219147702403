import React, { useState, useContext, useEffect } from 'react'
import { MegaContext } from '../Context/MegaContext';
import { AuthenticationContext } from "../Context/AuthenticationContext";
import useGetInfo from '../hooks/useGetInfo';

export default function PopUpAddress() {
    const authContext = useContext(AuthenticationContext);
    const megaContext = useContext(MegaContext);
    const [detail, setDetail] = useState(authContext.user.detail);

    const { cityItems, getCity } = useGetInfo();
    

    useEffect(() => {
        getCity();
    }, []);

    const handleDetail = (e) => {
        const { name, value } = e.target;
        let newDetail = { ...detail};
        newDetail[name] = value;
    
        setDetail(newDetail);
      };
    const formSubmit = (e) => {
        e.preventDefault();
        authContext.updateDetail(detail)
        
      };
    return (
        <>
            <div className="i-popup-confirmCode centered shown popup-edit-name" style={{ maxHeight: 'max-content', top: window.scrollY + 20, left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', zIndex: 301, opacity: 1, display: 'block' }}>
                <a href="#" className="j-close popup__close close" onClick={() => megaContext.setAddressPopUp(false)}/>
                <div className="popup__content">
                    <form onSubmit={formSubmit}>
                        <h2 className="popup__header">Əlavə məlumatlar</h2>
                        <ul className="form-list p-0">
                            <li className="form-list__item form-block">
                                <label className="form-block__label" htmlFor="phone">
                                    <span className="form-block__name">Telefon</span>
                                    <span className="form-block__text-decor">*</span>
                                </label>
                                <input type="text" name="phone" id="phone" className="val-msg" value={detail.phone} onChange={handleDetail} />
                            </li>
                            <li className="form-list__item form-block">
                                <label className="form-block__label" htmlFor="address">
                                    <span className="form-block__name">Ünvan</span>
                                    <span className="form-block__text-decor">*</span>
                                </label>
                                <input type="text" name="address" id="address" className="val-msg" value={detail.address} onChange={handleDetail}/>
                            </li>
                            <li className="form-list__item form-block">
                                <label className="form-block__label" htmlFor="city">
                                    <span className="form-block__name">Şəhər</span>
                                    <span className="form-block__text-decor">*</span>
                                </label>
                                <select name="city" id="city" className="val-msg" value={detail.city} onChange={handleDetail}>
                                    {
                                        cityItems ? cityItems.map(item => 
                                        <option value={item.id}>{item.name}</option>
                                        ) : null
                                    }
                                </select>
                            </li>
                            <li className="form-list__item form-block">
                                <label className="form-block__label" htmlFor="zip_code">
                                    <span className="form-block__name">Poçt kodu</span>
                                </label>
                                <input type="text" name="zip_code" id="zip_code" className="val-msg" value={detail.zip_code} onChange={handleDetail}/>
                            </li>
                        </ul>
                        <div className="popup__btn">
                            <button className="btn-main w-100" type="submit" >Saxla</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="i-overlay initially-hidden j-custom-overlay" style={{ zIndex: 300 }} onClick={() => megaContext.setAddressPopUp(false)}/>
        </>
    )
}
