import axios from "axios";


class InfoRepository {
    async getInfo() {

        const info = await axios.get(`${process.env.REACT_APP_API_URL}/info`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return info;
    }
    async getCity() {

        const city = await axios.get(`${process.env.REACT_APP_API_URL}/city`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return city;
    }
    async getAbout() {

        const about = await axios.get(`${process.env.REACT_APP_API_URL}/about`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return about;
    }
    async getShippingReturn() {

        const shipping_return = await axios.get(`${process.env.REACT_APP_API_URL}/shipping_return`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return shipping_return;
    }
    async getPrivacy() {

        const privacy = await axios.get(`${process.env.REACT_APP_API_URL}/privacy`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return privacy;
    }
}


export default new InfoRepository();