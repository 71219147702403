import React, {useContext, useEffect, useState} from 'react'
import useGetInfo from '../hooks/useGetInfo';
import { MegaContext } from "../Context/MegaContext";
import { AuthenticationContext } from "../Context/AuthenticationContext";
import { Link } from "react-router-dom";
import { Skeleton } from '@mui/material';
import { CartContext } from "../Context/CartContext";
import axios from "axios";

function AccountButton() {
    const auth = useContext(AuthenticationContext);
    
    if (auth.isAuthenticated) {
        let user = auth.user
        return (
            <Link to="/account" className="navbar-pc__link j-main-login">
                <span className="navbar-pc__icon navbar-pc__icon--profile" />
                {user.first_name}
            </Link>
        )
    }
    else {
        return (
            <Link to="/auth/login" className="navbar-pc__link j-main-login">
                <span className="navbar-pc__icon navbar-pc__icon--profile" />
                Giriş
            </Link>
        )
    }
}

export default function Header() {
    const { infoItems, getInfo } = useGetInfo();
    const [ products, setProducts] = useState();
    const context = useContext(MegaContext);
    const contextCart = useContext(CartContext);
    const searchProducts = async (wanted) => {
        const products = await axios.post(`${process.env.REACT_APP_API_URL}/search/products/${wanted}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        const data = products.products.length > 0 ? products.products : null
        setProducts(data)
    }
    const searchInput = (e) => {
        console.log(e.target.value)
        if(e.target.value.length > 0) {
            document.querySelector('.search-catalog__autocomplete').classList.remove('d-none')
            document.querySelector('.search-catalog__block').classList.add('search-catalog--active')
            searchProducts(e.target.value)
        }
        else {
            setProducts(null)
            document.querySelector('.search-catalog__autocomplete').classList.add('d-none')
            document.querySelector('.search-catalog__block').classList.remove('search-catalog--active')
        }
    }
    useEffect(() => {
        getInfo();
    }, []);
    return (
        <>
            <header className="header j-header header--fixed-menu header--fixed-animate">
                <div className="header__container">
                    <div className="header__top">
                        <ul className="header__simple-menu simple-menu">
                            <li className="simple-menu__item">{infoItems ? <a href={`tel:${infoItems.mobile}`} className="simple-menu__link simple-menu__link--sell-on-wb">Tel: {infoItems.mobile}</a> : <Skeleton style={{ width: '100px', height: '26px', borderRadius: '40px' }} />}</li>
                            <li className="simple-menu__item">{infoItems ? <a href={`mailto:${infoItems.email}`} className="simple-menu__link simple-menu__link--sell-on-wb">{infoItems.email}</a> : <Skeleton style={{ width: '100px', height: '26px', borderRadius: '40px' }} />}</li>
                            <li className="simple-menu__item">{infoItems ? <span  className="simple-menu__link simple-menu__link--sell-on-wb">{infoItems.address}</span> : <Skeleton style={{ width: '100px', height: '26px', borderRadius: '40px' }} />}</li>
                        </ul>
                    </div>
                    <div className="header__bottom">
                        <div className="header__nav-element nav-element">
                            <button className={`nav-element__burger j-menu-burger-btn ${context.menuBurger ? 'nav-element__burger--close' : ''}`} onClick={() => context.setMenuBurger(!context.menuBurger)} type="button" ><span className="nav-element__burger-line" /></button>
                            <Link to="/" className="nav-element__logo">
                                {
                                    infoItems ? <img src={`${process.env.REACT_APP_URL}assets/img/${infoItems.logo}`} alt={infoItems.logo} /> : <Skeleton style={{ width: '100%', height: '80px', borderRadius: '56px' }} />
                                }
                            </Link>
                            <div className="header__nav-icons">
                                <button className="nav-element__search j-search-header-icon hide-desktop" onClick={() => context.setSearchPopUp(true)} type="button" />
                            </div>
                        </div>
                        <div className="header__navbar-pc navbar-pc j-b-header-menu" id="basketContent">
                            <div className="navbar-pc__item">
                                <AccountButton />
                            </div>

                            <div className="navbar-pc__item">
                                <Link to="/cart" className="navbar-pc__link">
                                    <span className="navbar-pc__icon navbar-pc__icon--basket" >
                                        { contextCart.count ? <span className="navbar-pc__notify"> {contextCart.count} </span> : null}
                                    </span>
                                    Səbət
                                </Link>
                            </div>
                        </div>
                        <div id="searchBlock" className="header__search-catalog search-catalog j-search-catalog hide-mobile " >
                            <div className="search-catalog__block " >
                                {/* search-catalog--active */}
                                <input autoComplete="off" id="searchInput" onInput={searchInput} className="search-catalog__input" type="search" placeholder="Axtar" />
                                <div className="search-catalog__loading" ></div>

                                <button id="applySearchBtn" className="search-catalog__btn search-catalog__btn--search" type="button" >
                                    Məhsul axtarışı
                                </button>

                            </div>
                            <div className="autocomplete search-catalog__autocomplete d-none" >
                                <div className="autocomplete__content">
                                    <div className="autocomplete__scroll-container">
                                        <ul className="autocomplete__list autocomplete__list--catalog" >
                                            {
                                                products && products.map((product, index) => (
                                                    <li className="autocomplete__item j-suggest" >
                                                        <Link to={`/product/${product.slug}`}>
                                                            <p className="autocomplete__text">
                                                                <span className="autocomplete__icon loupe" />
                                                                <span className="autocomplete__phrase">{product.product_name}</span>
                                                            </p>
                                                        </Link>
                                                    </li>
                                                ))
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}
