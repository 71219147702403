import React, { useEffect } from 'react'
import useGetInfo from '../hooks/useGetInfo';
import { Markup } from 'interweave';
import Helmet from "react-helmet";

export default function ShippingReturn() {
    const { shippingReturnItems, getShippingReturn } = useGetInfo();
    useEffect(() => {
        getShippingReturn();
    }, []);
    return (
        <>
            <Helmet>
                <title>Mega az onlayn alış veriş mağazası</title>
                <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
                <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
            </Helmet>

            <Markup content={shippingReturnItems ? shippingReturnItems.shipping_return :  null} />
        </>
    )
}
