import React from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

function PriceList({ price, discount }) {
  let filtered = price.filter((item) => {
    if (item.color_id > 1 && item.size_id != null) {
      return true;
    }
  });
  if (filtered.length == 0) {
    filtered = price.filter((item) => {
      if (item.color_id > 1 && item.size_id == null) {
        return true;
      }
    });
  }
  if (filtered.length === 0) {
    filtered = price.filter((item) => {
      if (item.color_id == 1 && item.size_id != null) {
        return true;
      }
    });
  }
  if (filtered.length == 0) {
    filtered = price.filter((item) => {
      if (item.color_id == 1 && item.size_id == null) {
        return true;
      }
    });
  }

  const discountPrice = (price, discount) => {
    return (price - (price * discount) / 100).toFixed(2);
  };
  if (filtered.length > 0) {
    if (discount) {
      return (
        <>
          <span className="lower-price azn">
            {discountPrice(filtered[0].sale_price, discount)}
          </span>
          <span className="price-old-block azn">
            <del>{filtered[0].sale_price}</del>
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className="lower-price azn">{filtered[0].sale_price}</span>
        </>
      );
    }
  } else {
    return null;
  }
}

function Products({ products, setIsShown, setIsHide, quickView }) {
  if (products) {
    return products.map((product) => (
      <>
        <div
          id={`cp${product.id}`}
          className="product-card j-card-item"
          onMouseEnter={(e) => setIsShown(e)}
          onMouseLeave={(e) => setIsHide(e)}
          style={{ minHeight: 462 }}
        >
          <div className="product-card__wrapper">
            <Link
              draggable="false"
              className="product-card__main j-card-link mb-2"
              to={`/product/${product.slug}`}
            >
              <div className="product-card__img">
                <div className="product-card__img-wrap img-plug j-thumbnail-wrap">
                  <img
                    width={516}
                    height={688}
                    className="j-thumbnail thumbnail"
                    src={
                      product.images.length > 0
                        ? `${process.env.REACT_APP_URL}assets/img/products/${product.images[0].image_name}`
                        : null
                    }
                    alt={
                      product.images.length > 0
                        ? product.images[0].image_name
                        : null
                    }
                  />
                </div>
                <button
                  className="product-card__fast-view j-open-product-popup"
                  type="button"
                  onClick={(e) => quickView(e, product)}
                >
                  <i className="fa fa-search-plus"></i> Bax
                </button>
                {product.discount ? (
                  <span className="product-card__sale discount">
                    {product.discount}
                  </span>
                ) : null}
              </div>
              <div className="product-card__brand">
                <div className="product-card__price j-cataloger-price">
                  <span className="price">
                    <PriceList
                      price={product.price}
                      discount={product.discount}
                    />
                  </span>
                </div>
                <div className="product-card__brand-name">
                  <span className="goods-name">{product.product_name}</span>
                </div>
              </div>
              <ReactStars
                count={5}
                value={product.rating_sum_rating / product.rating.length}
                activeColor="#cb11ab"
                size={24}
                isHalf={true}
              />
            </Link>
            <div className="product-card__drop"></div>
          </div>
        </div>
      </>
    ));
  } else {
    return <h3>Mehsul Yoxdur</h3>;
  }
}

export default Products;
