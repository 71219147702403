// eslint-disable-next-line
import { useState } from 'react'

import CategoryRepository from '../repositories/CategoryRepository';

export default  function useGetCategories() {

    const [categoryItems, setCategoryItems] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState(null);
    const [category, setCategory] = useState(null);
    const [brands, setBrands] = useState(null);
    const [colors, setColors] = useState(null);
    const [sizes, setSizes] = useState(null);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState(1);


    return {
        categoryItems,
        products,
        category,
        brands,
        colors,
        sizes,
        error,
        setLoading,
        loading,
        count,
        page,
        pagination,

        getCategories: async () => {
            let responseData = await CategoryRepository.getCategories();

            if (responseData) {
                setCategoryItems(responseData.data.length > 0 && responseData.data);
            }
        },
        getCategory: async (payload) => {
            setCategory(null)
            let responseData = await CategoryRepository.getCategory(payload);

            if (responseData) {
                // console.log(responseData)
                if(responseData.status === 'success'){
                    setCategory(responseData.category);
                    setBrands(responseData.brands.length > 0 && responseData.brands);
                    setColors(responseData.colors.length > 0 && responseData.colors);
                    setSizes(responseData.sizes.length > 0 && responseData.sizes);
                }
                else{
                    setError(true)
                }

            }
        },
        getProducts: async (payload) => {
            setLoading(true)
            let responseData = await CategoryRepository.getProducts(payload);

            if (responseData) {
                setProducts(responseData.products.length > 0 && responseData.products);
                setPagination(responseData.pagination);
                setCount(responseData.count);
                setPage(responseData.page);
                setLoading(false)
            }
        }
    }
}

