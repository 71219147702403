import React, { useState, useContext } from "react";
import { MegaContext } from "../../Context/MegaContext";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthenticationContext } from "../../Context/AuthenticationContext";
import image from "../../assets/images/PersonalPhoto.png";
import Helmet from "react-helmet";

function Profile() {
	const authContext = useContext(AuthenticationContext);
	const megaContext = useContext(MegaContext);
	const [data, setData] = useState(authContext.user);

	const logout = async () => {
		let authentication = JSON.parse(localStorage.getItem("authentication"));

		axios.interceptors.request.use(
			(config) => {
				config.headers.authorization = `Bearer ${authentication.token}`;
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);
		let response = await axios.post(
			`${process.env.REACT_APP_API_URL}/user/logout`
		);
		response = response.data;

		if (response.status === "success") {
			toast.info(response.message);
			localStorage.removeItem("authentication");
			authContext.authLogout();
		}
	};
  	const deleteImage = async () => {
    let authentication = JSON.parse(localStorage.getItem("authentication"));

		axios.interceptors.request.use(
			(config) => {
				config.headers.authorization = `Bearer ${authentication.token}`;
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		const response = await axios({
			method: "delete",
			url: `${process.env.REACT_APP_API_URL}/user/image/delete/${authentication.user.id}`
		})
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));

		if (response.status === "success") {
			toast.success(response.message);
			authentication.user = response.user;
			localStorage.setItem(
				"authentication",
				JSON.stringify(authentication)
			);
		}
  }
	const handleData = (e) => {
		const { name, value } = e.target;
		let newData = { ...data };
		newData[name] = value;

		setData(newData);
		authContext.updateData(newData);
	};
	return (
		<>
			<Helmet>
				<title>Mega az onlayn alış veriş mağazası</title>
				<meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
				<meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
			</Helmet>

			<div className="personal-data-page">
				<section className="personal-data-page__section personal-data personal-data--main">
					<div className="text-end">
						<button
							type="button"
							className="logout"
							onClick={() => logout()}
						>
							Çıxış
						</button>
					</div>
					<div className="personal-data__top-wrap">
						<div className="user-photo">
              {
                authContext.user.avatar &&  <div className="user-photo-trash" onClick={deleteImage}><i className="fa fa-trash"></i></div>
              }

							<div className="user-photo__img-wrap img-plug">
								<img
									width={64}
									height={64}
									style={{ objectFit: "cover" }}
									alt="User avatar"
									src={
										authContext.user?.avatar
											? `${process.env.REACT_APP_URL}assets/img/user/${authContext.user?.avatar}`
											: image
									}
									onClick={() =>
										megaContext.setCropImagePopUp(true)
									}
								/>
							</div>
						</div>
						<div className="personal-data__header-wrap">
							<h2 className="personal-data__header">
								<span>
									{authContext.user?.first_name}{" "}
									{authContext.user?.last_name}
								</span>
							</h2>
							<button
								className="personal-data__edit personal-data__edit--name btn-edit"
								type="button"
								onClick={() => megaContext.setNamePopUp(true)}
							/>
						</div>
					</div>
					<ul className="personal-data__list personal-data__list--common">
						<li className="personal-data__item personal-data__item--email">
							<h3 className="personal-data__data-title">Şifrə</h3>
							<div className="personal-data__content">
								<button
									className="personal-data__edit btn-edit"
									type="button"
									onClick={() =>
										megaContext.setPasswordPopUp(true)
									}
								/>
							</div>
						</li>
						<li className="personal-data__item personal-data__item--email">
							<h3 className="personal-data__data-title">Email</h3>
							<div className="personal-data__content">
								<p className="personal-data__data">
									{authContext.user?.email}
								</p>
								<button
									className="personal-data__edit btn-edit"
									type="button"
									onClick={() =>
										megaContext.setEmailPopUp(true)
									}
								/>
							</div>
						</li>
						<li className="personal-data__item personal-data__item--phone">
							<h3 className="personal-data__data-title">
								Telefon
							</h3>
							<div className="personal-data__content">
								<p className="personal-data__data">
									{authContext.user?.mobile}
								</p>
								<button
									className="personal-data__edit btn-edit"
									type="button"
									onClick={() =>
										megaContext.setMobilePopUp(true)
									}
								/>
							</div>
						</li>
						<li className="personal-data__item personal-data__item--gender">
							<h3 className="personal-data__data-title">
								Cinsiyyət
							</h3>
							<form>
								<div className="personal-data__radio-wrap">
									<label className="personal-data__radio radio-with-text">
										<input
											type="radio"
											name="gender"
											value="male"
											onClick={handleData}
											checked={
												data?.gender === "male"
													? true
													: false
											}
										/>
										<span className="radio-with-text__decor" />
										<span className="radio-with-text__text ">
											Kişi
										</span>
									</label>
									<label className="personal-data__radio radio-with-text">
										<input
											type="radio"
											name="gender"
											value="female"
											onClick={handleData}
											checked={
												data.gender === "female"
													? true
													: false
											}
										/>
										<span className="radio-with-text__decor" />
										<span className="radio-with-text__text ">
											Qadın
										</span>
									</label>
								</div>
							</form>
						</li>
					</ul>
				</section>

				<section
					id="bankCard"
					className="personal-data-page__section payment-cards"
				>
					<h2 className="personal-data-page__header">
						Əlavə məlumatlar
					</h2>
					<ul className="payment-cards__list">
						<li
							className="payment-cards__item payment-cards__item--add d-block"
							style={{ width: "100%" }}
							onClick={() => megaContext.setAddressPopUp(true)}
						>
							<div
								className="text-center row justify-content-around"
								style={{
									display: "flex",
									justifyContent: "space-around",
									flex: "wrap",
								}}
							>
								<p className="personal-data__data col-auto">
									{data.detail?.phone}
								</p>
								<p className="personal-data__data col-auto">
									{data.detail?.state?.name}
								</p>
								<p className="personal-data__data col-auto">
									{data.detail?.address}
								</p>
								<p className="personal-data__data col-auto">
									{data.detail?.zip_code}
								</p>
								<button
									className="personal-data__edit btn-edit"
									style={{
										position: "absolute",
										top: "3px",
										right: "3px",
									}}
									type="button"
								/>
							</div>
						</li>
					</ul>
				</section>

				{/* <section id="discount" className="personal-data-page__section discount">
                    <h2 className="personal-data-page__header discount__header">
                        <span className="hide-desktop">Bonus sistemi</span>
                        <span className="hide-mobile">Bonus sistemi</span>
                        <span> 0 ₽</span>
                    </h2>
                    <div className="discount__content">
                        <p className="discount__info">Скидка зависит от суммы выкупа за весь период</p>
                        <ul className="discount__list" data-jsv-df>
                            <li className="discount__item active">
                                <p className="discount__percent">
                                    <span>5%</span>
                                    <del>3%</del>
                                </p>
                                <p className="discount__condition">
                                    <span>Ваша скидка повышена</span>
                                </p>
                            </li>
                            <li className="discount__item">
                                <p className="discount__percent">
                                    <span>5%</span>
                                </p>
                                <p className="discount__condition">
                                    <span>Сумма выкупа от&nbsp;100&nbsp;000&nbsp;₽</span>
                                </p>
                            </li>
                            <li className="discount__item">
                                <p className="discount__percent">
                                    <span>7%</span>
                                </p>
                                <p className="discount__condition">
                                    <span>Сумма выкупа от&nbsp;250&nbsp;000&nbsp;₽</span>
                                </p>
                            </li>
                            <li className="discount__item">
                                <p className="discount__percent">
                                    <span>10%</span>
                                </p>
                                <p className="discount__condition">
                                    <span>Сумма выкупа от&nbsp;500&nbsp;000&nbsp;₽</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="personal-data-page__section subscriptions">
                    <h2 className="personal-data-page__header">Obonelikler</h2>
                    <ul className="subscriptions__list" >
                        <li className="subscriptions__item subscriptions__item--first">
                            <fieldset className="subscriptions__fieldset">
                                <legend className="subscriptions__legend">Рассылки на почту</legend>
                                <label className="subscriptions__label checkbox-with-text">
                                    <input type="checkbox" />
                                    <span className="checkbox-with-text__decor" />
                                    <span className="checkbox-with-text__text">Личные рекомендации товаров</span>
                                </label>
                                <label className="subscriptions__label checkbox-with-text">
                                    <input type="checkbox" />
                                    <span className="checkbox-with-text__decor" />
                                    <span className="checkbox-with-text__text">Избранное</span>
                                </label>
                                <label className="subscriptions__label checkbox-with-text">
                                    <input type="checkbox" />
                                    <span className="checkbox-with-text__decor" />
                                    <span className="checkbox-with-text__text">Акции, персональные промокоды и секретные распродажи</span>
                                </label>
                            </fieldset>
                        </li>
                        <li className="subscriptions__item subscriptions__item--second">
                            <fieldset className="subscriptions__fieldset">
                                <legend className="subscriptions__legend">Рассылки по СМС</legend>
                                <label className="subscriptions__label checkbox-with-text">
                                    <input type="checkbox" />
                                    <span className="checkbox-with-text__decor" />
                                    <span className="checkbox-with-text__text">Включены</span>
                                </label>
                            </fieldset>
                        </li>
                    </ul>
                </section> */}
			</div>
		</>
	);
}

export default Profile;
