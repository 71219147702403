import React, { Component } from 'react'
import { Link } from "react-router-dom";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import axios from 'axios';
import serialize from 'form-serialize';
import { toast } from 'react-toastify';
import { AuthenticationContext } from "../../Context/AuthenticationContext";
import { CartContext } from "../../Context/CartContext";
import Helmet from "react-helmet";

export default class Login extends Component {
    static contextType = AuthenticationContext;

    login = async event => {

        event.preventDefault();

        var data = serialize(event.target, { hash: true })
        let cartId = JSON.parse(localStorage.getItem("cart_id"));
        data['cartId'] = cartId


        let response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, data)

        response = response.data

        if (response.status === 'success') {
            toast.success(response.message)
            localStorage.setItem('authentication', JSON.stringify(response.authentication))
            this.context.authLogin(response.authentication)
            localStorage.setItem('cart_id', JSON.stringify(response.cart_id))
        }
        if (response.status === 'warning') {
            let message = response.message;
            for (const value of Object.values(message)) {
                for (let i = 0; i < value.length; i++) {
                    toast.warning(value[i])
                }
            }
        }
        if (response.status === 'error') {
            toast.error(response.message)
        }

    }
    render() {
        return (
            <>
                <Helmet>
                    <title>Mega az onlayn alış veriş mağazası</title>
                    <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
                    <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
                </Helmet>
                <div className="sign-in-page">
                    <div className="sign-in-page__wrap">
                        <form id="spaAuthForm" onSubmit={this.login}>
                            <h2 className="sign-in-page__title" data-link="class{merge: step == 3 toggle='hide'}">Hesabınıza daxil olun</h2>
                            <div className="sign-in-page__login login">
                                <div className="login__phone form-block form-block--phone-dropdown">
                                    <label className="form-block__label">
                                        <span className="form-block__name">Email</span>
                                    </label>
                                    <div className="form-block__dropdown-wrap">
                                        <div className="form-block__input">
                                            <input className="input-item" type="text" name="email" />
                                        </div>
                                    </div>
                                </div>
                                <div className="login__phone form-block form-block--phone-dropdown">
                                    <label className="form-block__label">
                                        <span className="form-block__name">Şifrə</span>
                                    </label>
                                    <div className="form-block__dropdown-wrap">
                                        <div className="form-block__input">
                                            <input className="input-item" type="password" name="password" />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="remember" value="1"
                                               id="remember"/>
                                        <label className="form-check-label" htmlFor="remember">Məni xatırla</label>
                                    </div>
                                    <div className="form-check">
                                        <Link to="/auth/reset">Şifrəni sıfırla</Link>
                                    </div>
                                </div>

                                <button id="requestCode" className="login__btn btn-main-lg" type="submit" >Giriş</button>
                            </div>

                            <div className="sign-in-page__public p-0"><Link to="/auth/register"><PersonAddAltIcon /> <span style={{ verticalAlign: 'middle' }}>Yeni hesab</span></Link></div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
