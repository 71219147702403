import React, {useContext} from 'react'
import {
    useResolvedPath,
    Link
} from "react-router-dom";
import {AuthenticationContext} from "../../Context/AuthenticationContext";

export default function Menu() {
    const path = ['/account', '/account/wishlist', '/account/orders']
    const contextAuth = useContext(AuthenticationContext);
    let resolved = useResolvedPath(window.location.pathname);
    return (
        <>
            <div className="lk-menu">
                <div className={`lk-menu__container mb-40 j-lk-menu-top ${path.includes(resolved.pathname) && contextAuth.isAuthenticated ? '' : 'hide'}`}>
                    <div className="lk-menu-main">
                        <div className="lk-menu-main__wrapper">
                            <ul className="lk-menu-main__content j-croll-content">
                                <li className={`lk-menu-main__item lk-menu-main__item--profile ${resolved.pathname === '/account' ? 'active' : ''}`} >
                                    <Link className="lk-menu-main__title" to="/account">Profil</Link>
                                </li>
                                <li className={`lk-menu-main__item lk-menu-main__item--favorites ${resolved.pathname === '/account/wishlist' ? 'active' : ''}`} >
                                    <Link className="lk-menu-main__title" to="/account/wishlist">Seçilmişlər</Link>
                                </li>
                                <li className={`lk-menu-main__item lk-menu-main__item--orders ${resolved.pathname === '/account/orders' ? 'active' : ''}`} >
                                    <Link className="lk-menu-main__title" to="/account/orders">Sifarişlər</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
