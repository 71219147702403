import React, { useEffect } from "react";
import useGetProducts from "../../hooks/useGetProducts";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Helmet from "react-helmet";

export default function WishList() {
	const { wishListItems, getWishList } = useGetProducts();
	const removeWishList = async (e, id) => {
        e.preventDefault();
		let authentication = JSON.parse(localStorage.getItem("authentication"));

		const response = await axios
			.put(`${process.env.REACT_APP_API_URL}/wishlist/${id}`, {
				user_id: authentication.user.id,
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));

		console.log(response);

		if (response.status == "success") {
			toast.success("Məhsul sevimlilərdən silindi");
            getWishList();
		} else {
			toast.error("Xəta");
			console.log(response);
		}
	};
	useEffect(() => {
		getWishList();
		console.log(wishListItems);
	}, []);
	return (
		<>
			<Helmet>
				<title>Mega az onlayn alış veriş mağazası</title>
				<meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
				<meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
			</Helmet>
			<div className="favorites-page">
				<div className="favorites-page__goods">
					<div className="favorites-goods">
						<ul className="favorites-goods__list">
							{/* <li className="favorites-goods__similar-goods j-b-similar-goods-wrapper" /> */}
							{wishListItems ? (
								wishListItems.map((product) => {
									return (
										<li className="favorites-goods__item goods-card goods-card--favorites j-favorite-good-item">
											<div className="goods-card__container">
												<Link
													className="goods-card__container j-product-popup"
													to={`/product/${product.slug}`}
												>
													<div className="goods-card__img-wrap img-plug">
														<img
															src={
																product.images
																	.length > 0
																	? `${process.env.REACT_APP_URL}assets/img/products/${product.images[0].image_name}`
																	: null
															}
															alt={
																product.images
																	.length > 0
																	? product
																			.images[0]
																			.image_name
																	: null
															}
														/>
														<button
															className="goods-card__remove j-remove-single"
															title="Sil"
                                                            type="button"
                                                            onClick={(e) => removeWishList(e, product.id)}
														>
															<span className="achtung-icon-white">
																Sil
															</span>
														</button>
														<div className="goods-card__badge-wrap">
															{product.discount ? (
																<p className="goods-card__discount">
																	-
																	{
																		product.discount
																	}
																	%
																</p>
															) : null}
														</div>
													</div>
													<div className="goods-card__info">
														<div className="goods-card__info-top">
															<p className="goods-card__price">
																<PriceList
																	price={
																		product.price
																	}
																	discount={
																		product.discount
																	}
																/>
															</p>
														</div>
														<p className="goods-card__description">
															<span className="good-name">
																{
																	product.product_name
																}
															</span>
														</p>
													</div>
												</Link>
											</div>
										</li>
									);
								})
							) : (
								<li className="favorites-goods__similar-goods j-b-similar-goods-wrapper">
									<div className="basket-page j-b-lk-basket desktop">
										<div className="basket-page__blocks-wrap j-order-details">
											<div className="basket-page__basket-empty basket-empty">
												<h1 className="text-center">
													Məhsul yoxdur
												</h1>
											</div>
										</div>
									</div>
								</li>
							)}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}

function PriceList(props) {
	let filtered = props.price.filter((item) => {
		if (item.color_id > 1 && item.size_id != null) {
			return true;
		}
	});
	if (filtered.length == 0) {
		filtered = props.price.filter((item) => {
			if (item.color_id > 1 && item.size_id == null) {
				return true;
			}
		});
	}
	if (filtered.length === 0) {
		filtered = props.price.filter((item) => {
			if (item.color_id == 1 && item.size_id != null) {
				return true;
			}
		});
	}
	if (filtered.length == 0) {
		filtered = props.price.filter((item) => {
			if (item.color_id == 1 && item.size_id == null) {
				return true;
			}
		});
	}

	const discountPrice = (price, discount) => {
		return (price - (price * discount) / 100).toFixed(2);
	};
	if (filtered.length > 0) {
		if (props.discount) {
			return (
				<>
					<span className="goods-card__price-now azn">
						{discountPrice(filtered[0].sale_price, props.discount)}
					</span>
					<span className="goods-card__price-last azn">
						{filtered[0].sale_price}
					</span>
				</>
			);
		} else {
			return (
				<>
					<span className="goods-card__price-now azn">
						{filtered[0].sale_price}
					</span>
				</>
			);
		}
	} else {
		return null;
	}
}
