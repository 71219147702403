import { useState } from 'react'

import OrderRepository from "../repositories/OrdersRepository";

export default  function useGetOrders() {

    const [orders, setOrders] = useState(null);
    const [order, setOrder] = useState(null);

    return {
        orders,
        order,

        getOrders: async (params) => {
            let responseData = await OrderRepository.getOrders(params);

            if (responseData) {
                setOrders(responseData.orders.length ? responseData.orders : null);
            }
        },
        getOrder: async (params) => {
            let responseData = await OrderRepository.getOrder(params);

            if (responseData) {
                setOrder(responseData.order ? responseData.order : null);
            }
        },

    }
}

