import React, {useState, useContext, useEffect} from "react";
import {MegaContext} from "../../Context/MegaContext";
import {CartContext} from "../../Context/CartContext";
import {Link, useNavigate, useLocation, useParams} from "react-router-dom";
import Helmet from 'react-helmet';
import SwiperCore, {
    Navigation,
    Pagination,
    EffectFade,
    A11y,
    Autoplay,
} from "swiper";
import axios from "axios";
import {toast} from "react-toastify";
import ReactStars from "react-rating-stars-component";
import PersonalPhoto from "../../assets/images/PersonalPhoto.png";
import * as moment from "moment";
import {Swiper, SwiperSlide} from "swiper/react";
import useGetProducts from "../../hooks/useGetProducts";
import {Markup} from "interweave";

SwiperCore.use([Navigation, Pagination, A11y, EffectFade, Autoplay]);

export default function Product() {
    const context = useContext(MegaContext);
    const contextCart = useContext(CartContext);
    const {product, relatedProducts, getProduct} = useGetProducts();
    const navigate = useNavigate();
    const location = useLocation();
    const {slug} = useParams();
    const quickView = (e, item) => {
        e.preventDefault();
        context.setQuickView(true, item);
    };

    // const [params, setParams] = useState(true)
    const [filteredImages, setFilteredImages] = useState([]);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);
    const [piece, setPiece] = useState(1);
    const [detail, setDetail] = useState(false);

    // const [activePage, setActivePage] = useState(1);
    // const [pageSize, setPageSize] = useState(1);
    // const [reviews, setReviews] = useState([]);
    // const styles = {
    //   hideBackNextButtonText: true,
    //   hidePaginatorInfo: true,
    //   backAndNextTextButtonColor: "black",
    //   paginatorButtonColor: "#fff",
    //   pageSizehideBackNextButtonText: true,
    //   paginatorButtonBackgroundColor: "#FFF8DC",
    //   paginatorButtonSelectedColor: "#fff",
    //   paginatorButtonHoverColor: "#F0F8FF",
    //   lateralMargin: "0 2rem",
    //   paginatorButtonSelectedBackgroundColor: '#cb11ab',
    //   paginatorButtonHoverColor: '#cb11ab',
    //   paginatorButtonBackgroundColor: '#e313bf8f'
    // };

    const ratingChanged = async (rating) => {
        let response = await axios.post(
            `${process.env.REACT_APP_API_URL}/rating`,
            {
                rating: rating,
                product_id: product.id,
            }
        );

        response = response.data;

        if (String(response.status) === "success") {
            toast.success(response.message);
        }
    };

    const imageView = (e) => {
        e.preventDefault();

        document.querySelector(".photo-zoom__preview").src = e.target.getAttribute("data-main-name");
    };

    const addToCart = () => {
        let colors = product.price.filter((item) => Number(item.color_id) > 0);
        let sizes = product.price.filter((item) => Number(item.size_id) > 0);

        if (colors.length > 0 && !selectedColor) {
            toast.error("Zəhmət olmasa məhsul rəngini seçin");
        } else if (sizes.length > 0 && !selectedSize) {
            toast.error("Zəhmət olmasa məhsul ölçüsünü seçin");
        }
        else if (
            product.price.length === 0 ||
            product.price.filter( (item) => Number(item.color_id) === Number(selectedColor) && Number(item.size_id) === Number(selectedSize)).length === 0 ||
            !(
                product.price.find(
                    (item) =>
                        Number(item.color_id) === Number(selectedColor) &&
                        Number(item.size_id) === Number(selectedSize)
                )?.stock_piece > 0
            )
        ) {
            toast.error("Məhsul anbarda mövcud deyil");
        } else if (
            product.price.find(
                (item) =>
                    Number(item.color_id) === Number(selectedColor) &&
                    Number(item.size_id) === Number(selectedSize)
            )?.stock_piece < piece
        ) {
            toast.error("Seçilən məhsul sayı anbar sayından çoxdur");
        } else {
            let priceList = product.price.filter(
                (item) =>
                    Number(item.color_id) === Number(selectedColor) &&
                    Number(item.size_id) === Number(selectedSize)
            );

            let authentication = JSON.parse(localStorage.getItem("authentication"));
            let cartId = JSON.parse(localStorage.getItem("cart_id"));

            let amount = priceList.length > 0 ? priceList[0].sale_price : 0;
            let priceId = priceList.length > 0 ? priceList[0].id : null;
            contextCart.addToCart({
                piece: piece,
                product_id: product.id,
                price_id: priceId,
                amount: amount,
                size_id: selectedSize,
                color_id: selectedColor,
                cart_id: cartId,
                user: authentication ? authentication.user.id : null
            });
        }
    };

    const selectColor = (e, colorId) => {
        setSelectedColor(Number(colorId));
        setSelectedSize(null);
        // setFilteredPrice(
        //   product.price.filter((sizeItem) => sizeItem.color_id === colorId)
        // );
        let images = product.images.filter(
            (imageItem) => Number(imageItem.color_id) === Number(colorId)
        );
        if (images.length === 0) {
            images = product.images;
        }
        document.querySelector(".photo-zoom__preview").src = `${process.env.REACT_APP_URL}assets/img/products/${images[0]?.main_name}`;
        setFilteredImages(images);
    };

    const selectSize = (e, sizeId) => {
        if (!selectedColor) {
            toast.warning("İlk öncə rəng seçin");
            return false;
        }
        setSelectedSize(Number(sizeId));
    };

    const updatePiece = (option) => {
        if (option === "down") {
            let newPiece = piece - 1;
            if (newPiece < 1) {
                newPiece = 1;
            }

            setPiece(newPiece);
        } else if (option === "up") {
            let newPiece = piece + 1;

            setPiece(newPiece);
        }
    };

    const addWishList = async (id) => {

        if (localStorage.hasOwnProperty("authentication")) {
            let authentication = JSON.parse(localStorage.getItem("authentication"));

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/wishlist`, {
                user_id: authentication.user.id,
                product_id: id
            })
                .then((response) => {
                    return response.data;
                })
                .catch((error) => ({error: JSON.stringify(error)}));

            if (String(response.status) === 'success') {
                toast.success("Məhsul sevimlilərə əlavə edildi");
            } else {
                toast.error("Xəta");
            }
        } else {
            toast.info("Məhsulu sevimlilərə əlavə etməy üçün giriş edin");
        }
    }

    const recentlyAdd = (id) => {
        let recently = sessionStorage.getItem('recently_viewed') ? JSON.parse(sessionStorage.getItem('recently_viewed')) : [];

        recently.push(id)

        // function onlyUnique(value, index, self) {
        //     return self.indexOf(value) === index;
        // }

        recently = recently.filter(function (value, index, self) {
            return self.indexOf(value) === index;
        })

        sessionStorage.setItem('recently_viewed', JSON.stringify(recently))
    }

    useEffect(() => {
        getProduct(slug, setFilteredImages, setSelectedColor);

        function handleResize() {
            if (window.innerWidth < 1024) {
                context.setQuickView(false, null);
            }
        }

        window.addEventListener("resize", handleResize);
    }, [location]);

    if (product) {
        recentlyAdd(product.id)
        return (
            <>
                <Helmet>
                    <title>{product.product_name}</title>
                    <meta name="description" content={product.meta_discription} />
                    <meta name="keyword" content={product.meta_title} />
                </Helmet>
                <div className="product-detail" id="container">
                    <div className="product-detail__top-wrap hide-mobile">
                        <div className="product-detail__breadcrumbs breadcrumbs">
                            <button
                                className="breadcrumbs__back btn-base-sm"
                                onClick={() => navigate(-1)}
                            >
                                Geri
                            </button>
                            <div className="breadcrumbs__container">
                                <ul
                                    className="breadcrumbs__list"
                                    style={{display: "flex"}}
                                >
                                    <li className="breadcrumbs__item">
                                        <Link
                                            to="/"
                                            className="breadcrumbs__link"
                                        >
                                            <span>Ana Səhifə</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumbs__item">
                                        <Link
                                            to={`/catalog/${product.categories[0]?.slug}`}
                                            className="breadcrumbs__link"
                                        >
											<span>
												{
                                                    product.categories[0]?.category_name
                                                }
											</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumbs__item">
                                        <Link
                                            to={`/brand/${product.brands[0]?.slug}`}
                                            className="breadcrumbs__link"
                                        >
											<span>
												{product.brands[0]?.name}
											</span>
                                        </Link>
                                    </li>
                                    <li className="breadcrumbs__item">
                                        <span>{product.product_name}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="product-detail__same-part-kt same-part-kt">
                        <div className="same-part-kt">
                            <div className="same-part-kt__slider-wrap j-card-left-wrap">
                                <div className="same-part-kt__sticky-wrap j-card-left">
                                    <div className="same-part-kt__slider">
                                        <div className="sw-slider-kt-mix">
                                            <button
                                                className="swiper-button-prev swiper-vertical-button-prev"
                                                type="button"
                                                tabIndex={-1}
                                            >
                                                Əvvəlki slayd
                                            </button>
                                            <div className="sw-slider-kt-mix__wrap">
                                                <Swiper
                                                    // modules={[Navigation, Pagination, A11y]}
                                                    spaceBetween={10}
                                                    slidesPerView={2}
                                                    direction="horizontal"
                                                    centeredSlides={true}
                                                    navigation={{
                                                        nextEl: ".swiper-vertical-button-next",
                                                        prevEl: ".swiper-vertical-button-prev",
                                                    }}
                                                    className="j-sw-images-carousel"
                                                    breakpoints={{
                                                        576: {
                                                            slidesPerView: 2,
                                                            spaceBetween: 10,
                                                            direction:
                                                                "horizontal",
                                                            centeredSlides: true,
                                                        },
                                                        1024: {
                                                            slidesPerView: 5,
                                                            spaceBetween: 15,
                                                            direction:
                                                                "vertical",
                                                            centeredSlides: false,
                                                        },
                                                    }}
                                                >
                                                    {filteredImages.length >
                                                        0 &&
                                                        filteredImages.map(
                                                            (item) => (
                                                                <SwiperSlide className="slide">
                                                                    <div className="slide__content img-plug">
                                                                        <img
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                imageView(
                                                                                    e
                                                                                )
                                                                            }
                                                                            onMouseOver={(
                                                                                e
                                                                            ) =>
                                                                                imageView(
                                                                                    e
                                                                                )
                                                                            }
                                                                            src={`${process.env.REACT_APP_URL}assets/img/products/${item.image_name}`}
                                                                            alt={
                                                                                item.image_name
                                                                            }
                                                                            data-main-name={`${process.env.REACT_APP_URL}assets/img/products/${item.main_name}`}
                                                                        />
                                                                    </div>
                                                                </SwiperSlide>
                                                            )
                                                        )}
                                                </Swiper>
                                                <div
                                                    className="sw-slider-kt-mix__btn-wrap hide-desktop justify-content-between">
                                                    <button
                                                        className="sw-slider-kt-mix__btn sw-slider-kt-mix__btn--back"
                                                        onClick={() =>
                                                            navigate(-1)
                                                        }
                                                    />
                                                    <button
                                                        className="sw-slider-kt-mix__btn sw-slider-kt-mix__btn--to-poned"/>
                                                </div>
                                            </div>
                                            <button
                                                className="swiper-button-next swiper-vertical-button-next"
                                                type="button"
                                                tabIndex={0}
                                            >
                                                Növbəti slayd
                                            </button>
                                        </div>
                                    </div>
                                    <div className="same-part-kt__mix-block hide-mobile">
                                        <div id="photo" className="mix-block">
                                            <div
                                                id="imageContainer"
                                                className="mix-block__photo-zoom photo-zoom"
                                            >
                                                <div
                                                    className="photo-zoom__img-plug img-plug"
                                                    style={{opacity: 1}}
                                                >
                                                    {/* <ReactImageZoom zoomWidth="500" img="//images.wbstatic.net/big/new/12500000/12500651-1.jpg" className="photo-zoom__preview j-zoom-preview" style={{ zIndex: 305 }} /> */}
                                                    <img
                                                        className="photo-zoom__preview j-zoom-preview"
                                                        src={
                                                            product.images
                                                                .length > 0
                                                                ? `${process.env.REACT_APP_URL}assets/img/products/${product.images[0].main_name}`
                                                                : null
                                                        }
                                                        alt={
                                                            product.images
                                                                .length > 0
                                                                ? product
                                                                    .images[0]
                                                                    .image_name
                                                                : product.product_name
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="same-part-kt__header-wrap hide-mobile">
                                <h1 className="same-part-kt__header">
                                    <span>{product.product_name} </span>
                                </h1>
                                <div className="same-part-kt__common-info">
                                    <p
                                        className="same-part-kt__review-wrap"
                                        id="comments_reviews_link"
                                    >
                                        <ReactStars
                                            count={5}
                                            onChange={ratingChanged}
                                            value={
                                                product.rating_sum_rating /
                                                product.rating.length
                                            }
                                            activeColor="#cb11ab"
                                            size={24}
                                            isHalf={true}
                                        />
                                        <span className="same-part-kt__count-review">
											{product.reviews.length} rəy
										</span>
                                    </p>
                                    <p className="same-part-kt__order-quantity j-orders-count-wrapper">
                                        {" "}
                                        {product.best_selling} dəfədən çox
                                        alınıb{" "}
                                    </p>
                                </div>
                            </div>
                            <div
                                id="infoBlockProductCard"
                                className="same-part-kt__info-wrap"
                            >
                                <div
                                    className={`same-part-kt__sold-out-product ${
                                        product.price_sum_stock_piece
                                            ? "d-none"
                                            : ""
                                    }`}
                                >
                                    <p className="sold-out-product ">
										<span className="sold-out-product__text">
											Məhsul anbarda tükənib
										</span>
                                    </p>
                                </div>
                                <div className="same-part-kt__price-block">
                                    <div className="price-block">
                                        <div className="price-block__content">
                                            <p className="price-block__price-wrap">
                                                <PriceList
                                                    product={product}
                                                    oldC="price-block__old-price j-final-saving"
                                                    newC="price-block__final-price"
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <ColorList
                                    product={product}
                                    selectedColor={selectedColor}
                                    selectColor={selectColor}
                                />

                                <SizeList
                                    product={product}
                                    // filteredPrice={filteredPrice}
                                    selectedSize={selectedSize}
                                    selectedColor={selectedColor}
                                    selectSize={selectSize}
                                />
                                <div className="same-part-kt__order">
                                    <div className="same-part-kt__btn-wrap row">
                                        <div className="col-6 col-lg-12 align-self-center">
                                            <button
                                                className="btn-main w-100"
                                                disabled={product.price.length === 0}
                                                onClick={(e) => addToCart(e)}
                                            >
                                                Səbətə at
                                            </button>
                                        </div>
                                        <div className="col-6 col-lg-12 align-self-center">
                                            <div
                                                className="same-part-kt__btn-qt btn-qt-goods w-100"
                                                style={{
                                                    marginTop: "5px",
                                                    maxWidth: "max-content",
                                                }}
                                            >
                                                <button
                                                    className="btn-qt-goods__edit btn-qt-goods__edit--minus"
                                                    onClick={() =>
                                                        updatePiece("down")
                                                    }
                                                >
                                                    -
                                                </button>
                                                <input
                                                    className="numeric piece"
                                                    value={piece}
                                                    type="text"
                                                    style={{
                                                        width: "60px",
                                                        textAlign: "center",
                                                    }}
                                                />
                                                <button
                                                    className="btn-qt-goods__edit btn-qt-goods__edit--plus"
                                                    onClick={() =>
                                                        updatePiece("up")
                                                    }
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                    <button
                                        className="same-part-kt__to-poned hide-mobile btn-heart add"
                                        onClick={() => addWishList(product.id)}
                                        type="button"
                                    ></button>
                                </div>
                                <div className="same-part-kt__header-wrap hide-desktop">
                                    <h1 className="same-part-kt__header">
                                        <span>{product.product_name}</span>
                                    </h1>
                                    <div className="same-part-kt__spec-action">
                                        <div className="spec-action hide">
                                            <span className="spec-action__text"/>
                                        </div>
                                    </div>
                                    <div className="same-part-kt__brand-logo hide-desktop">
                                        <Link className="img-plug"
                                              id="brandBannerImgRef"
                                              title={product.brands[0]?.name} to={`/brand/${product.brands[0]?.slug}`}>
                                            {product.brands[0]?.image ? (
                                                <img
                                                    width={120}
                                                    alt={
                                                        product.brands[0]?.image
                                                    }
                                                    src={`${process.env.REACT_APP_URL}assets/img/brand/${product.brands[0]?.image}`}
                                                />
                                            ) : (
                                                product.brands[0]?.name
                                            )}
                                        </Link>
                                    </div>
                                    <div className="same-part-kt__common-info align-items-center">
                                        <p className="same-part-kt__article">
                                            <span>SKU:</span>
                                            <span id="productNmId">
												{product.sku}
											</span>
                                        </p>
                                        <p
                                            className="same-part-kt__review-wrap"
                                            id="comments_reviews_link"
                                        >
                                            <ReactStars
                                                count={5}
                                                onChange={ratingChanged}
                                                value={
                                                    product.rating_sum_rating /
                                                    product.rating.length
                                                }
                                                activeColor="#cb11ab"
                                                size={24}
                                                isHalf={true}
                                            />
                                        </p>
                                        <p className="ms-2 same-part-kt__count-review">
                                            {" "}
                                            {product.reviews.length} rəy{" "}
                                        </p>
                                        <p className="same-part-kt__order-quantity j-orders-count-wrapper">
											<span>
												{product.best_selling} dəfədən
												çox alınıb{" "}
											</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="same-part-kt__delivery-advantages">
                                    {/* <div className="same-part-kt__seller seller" >
                                        <div className="seller__content">
                                            <span className="seller__title">Продавец</span>
                                            <div className="seller__wrap">
                                                <a className="seller__name seller__name--short seller__name--link" href="/seller/42122" >ИП Кашина Виктория Олеговна</a>
                                                <span className="tip-info" />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="same-part-kt__logo-wrap hide-mobile">
                                        <div className="same-part-kt__brand-logo">
                                            <Link className="img-plug"
                                                  id="brandBannerImgRef"
                                                  title={product.brands[0]?.name}
                                                  to={`/brand/${product.brands[0]?.slug}`}>
                                                {product.brands[0]?.image ? (
                                                    <img
                                                        width={120}
                                                        alt={
                                                            product.brands[0]?.image
                                                        }
                                                        src={`${process.env.REACT_APP_URL}assets/img/brand/${product.brands[0]?.image}`}
                                                    />
                                                ) : (
                                                    product.brands[0]?.name
                                                )}
                                            </Link>
                                            <a
                                                className="img-plug"
                                                id="brandBannerImgRef"
                                                title={product.brands[0]?.name}
                                                href={`/brand/${product.brands[0]?.slug}`}
                                            >

                                            </a>
                                        </div>
                                    </div>
                                    {/* <div className="same-part-kt__advantages advantages" >
                                        <ul className="advantages__list">
                                            <li className="advantages__item advantages__item--delivery" >Бесплатная доставка</li>
                                            <li className="advantages__item advantages__item--fitting" >Примерка</li>
                                            <li className="advantages__item advantages__item--refund" >21 день на возврат</li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="product-detail__section-wrap">
                        <div className="product-detail__details-wrap">
                            <section className="product-detail__details details">
                                <div className="details__header-wrap">
                                    <h2 className="details__header section-header">
                                        Təsvir
                                    </h2>
                                </div>
                                <div
                                    className="details__content collapsable"
                                    style={{position: "relative"}}
                                >
                                    <div>
                                        <div
                                            className="collapsable__content j-add-info-section"
                                            style={{
                                                maxHeight: detail ? "none" : 86,
                                            }}
                                        >
                                            <div className="product-params">
                                                <Markup
                                                    content={
                                                        product.product_description
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="collapsible__bottom">
                                            <div
                                                className="collapsible__gradient"
                                                style={{
                                                    display: detail
                                                        ? "none"
                                                        : "",
                                                }}
                                            />
                                            <div className="collapsible__toggle-wrap">
                                                <button
                                                    className="collapsible__toggle"
                                                    onClick={() =>
                                                        setDetail(!detail)
                                                    }
                                                    type="button"
                                                >
                                                    {detail
                                                        ? "Daha az"
                                                        : "Daha çox"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                    <Related products={relatedProducts} quickView={quickView}/>

                    <section className="product-detail__user-activity user-activity hide">
                        <div className="user-activity__header-wrap">
                            <h2 className="user-activity__header section-header">
                                Məhsul rəyləri
                            </h2>
                            <button
                                className="user-activity__menu hide-desktop"
                                type="button"
                            />
                        </div>
                        <div className="user-activity__tabs-wrap">
                            <ul className="user-activity__tabs" id="footerTabs">
                                <li
                                    className="user-activity__tab active"
                                    data-content="Comments"
                                >
                                    <a id="a-Comments" href>
                                        Rəylər {product.reviews.length}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="user-activity__content"
                            id="tabs-content"
                        >
                            <div id="newFeedback" className="new-feedback">
                                <div
                                    className="new-feedback__wrap"
                                    style={{display: "block"}}
                                >
                                    <div className="new-feedback__content">
                                        <button
                                            className="new-feedback__btn btn-main"
                                            type="button"
                                            onClick={() =>
                                                context.setNewReview(
                                                    true,
                                                    product.id
                                                )
                                            }
                                        >
                                            Rəy yazın
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="comments"
                                id="Comments"
                                style={{display: "block"}}
                            >
                                <div>
                                    <div className="comments__content">
                                        <div className="comments__sorting-wrap">
                                            <ul className="comments__list">
                                                {product.reviews.length > 0 &&
                                                    product.reviews.map(
                                                        (review) => (
                                                            <li className="comments__item feedback">
                                                                <div className="feedback__top-wrap">
                                                                    <div className="feedback__img-block">
																		<span className="feedback__img-wrap img-plug">
																			<img
                                                                                className="feedback__img"
                                                                                src={
                                                                                    PersonalPhoto
                                                                                }
                                                                                width={
                                                                                    40
                                                                                }
                                                                                height={
                                                                                    40
                                                                                }
                                                                                alt="User avatar"
                                                                            />
																		</span>
                                                                    </div>
                                                                    <div className="feedback__info">
																		<span className="feedback__header">
																			{
                                                                                review.name
                                                                            }
																		</span>
                                                                        <span className="feedback__date">
																			{moment(
                                                                                review.created_at
                                                                            ).format(
                                                                                "DD MMM YYYY H:m"
                                                                            )}
																		</span>
                                                                        <div className="feedback__wrap">
																			<span
                                                                                className={
                                                                                    `feedback__rating stars-line star` +
                                                                                    review.rating
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="feedback__content">
                                                                    <p
                                                                        className="feedback__text"
                                                                        itemProp="reviewBody"
                                                                    >
                                                                        {
                                                                            review.review
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        )
                                                    )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <Paginator page={activePage} pageSize={pageSize} pageGroupSize={7} styles={styles} totalItems={product.reviews.length} /> */}
                        </div>
                    </section>
                </div>
            </>
        );
    } else {
        return <div className="general-preloader"></div>;
    }
}

function PriceList({product, oldC, newC}) {
    let filtered = product.price.filter((item) => {
        return item.color_id > 1 && item.size_id != null;
    });
    if (filtered.length === 0) {
        filtered = product.price.filter((item) => {
            return item.color_id > 1 && item.size_id == null;
        });
    }
    if (filtered.length === 0) {
        filtered = product.price.filter((item) => {
            return Number(item.color_id) === 1 && item.size_id != null;
        });
    }
    if (filtered.length === 0) {
        filtered = product.price.filter((item) => {
            return Number(item.color_id) === 1 && item.size_id == null;
        });
    }

    const discountPrice = (price, discount) => {
        return (price - (price * discount) / 100).toFixed(2);
    };

    if (product.price.length > 0) {
        if (product.discount) {
            return (
                <>
					<span className={`${newC} azn`}>
						{discountPrice(
                            filtered.length > 0 ? filtered[0].sale_price : 0,
                            product.discount
                        )}
					</span>
                    <del className={`${oldC} azn`}>
                        {filtered.length > 0 ? filtered[0].sale_price : 0}
                    </del>
                </>
            );
        } else {
            return (
                <>
					<span className={`${newC} azn`}>
						{filtered.length > 0 ? filtered[0].sale_price : 0}
					</span>
                </>
            );
        }
    } else {
        return null;
    }
}

function Related({products, quickView}) {
    if (products) {
        return (
            <div className="product-detail__goods-slider j-promo-carousel-wrapper">
                <div className="j-b-recommended-goods-wrapper">
                    <section className="j-b-recommended-goods-wrapper goods-slider j-b-slider-wrapper recently-watched">
                        <div className="goods-slider__header-wrap">
                            <h2 className="goods-slider__header section-header">
                                Əlaqədar məhsullar
                            </h2>
                        </div>
                        <div className="sw-slider-goods j-carousel j-products-container">
                            <button
                                className="swiper-button-prev goods-button-prev"
                                type="button"
                                tabIndex="-1"
                            ></button>
                            <Swiper
                                modules={[Navigation, Pagination, A11y]}
                                spaceBetween={10}
                                slidesPerView={3}
                                navigation={{
                                    nextEl: ".goods-button-next",
                                    prevEl: ".goods-button-prev",
                                }}
                                className=""
                                breakpoints={{
                                    576: {
                                        slidesPerView: 4,
                                        spaceBetween: 10,
                                    },
                                    768: {
                                        slidesPerView: 5,
                                        spaceBetween: 10,
                                    },
                                    992: {
                                        slidesPerView: 6,
                                        spaceBetween: 20,
                                    },
                                    1200: {
                                        slidesPerView: 7,
                                        spaceBetween: 20,
                                    },
                                }}
                            >
                                {products.map((product) => (
                                    <SwiperSlide
                                        key={product.id}
                                        className="j-product-item goods-card"
                                    >
                                        <Link
                                            key={product.slug}
                                            to={`/product/${product.slug}`}
                                        >
                                            <div className="goods-card__container">
                                                <div
                                                    className="goods-card__img-wrap img-plug"
                                                    data-plug-text="Фото отсутствует"
                                                >
                                                    <img
                                                        src={
                                                            product.images
                                                                .length > 0
                                                                ? `${process.env.REACT_APP_URL}assets/img/products/${product.images[0].image_name}`
                                                                : null
                                                        }
                                                        alt={
                                                            product.images
                                                                .length > 0
                                                                ? product
                                                                    .images[0]
                                                                    .image_name
                                                                : "null"
                                                        }
                                                    />
                                                    <button
                                                        className="goods-card__preview-btn j-open-product-popup"
                                                        onClick={(e) =>
                                                            quickView(
                                                                e,
                                                                product
                                                            )
                                                        }
                                                        type="button"
                                                    >
                                                        <i className="fa fa-search-plus"></i>{" "}
                                                        Bax
                                                    </button>
                                                    {product.discount ? (
                                                        <p className="goods-card__discount discount">
                                                            {product.discount}
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className="goods-card__info">
                                                    <p className="goods-card__price">
                                                        <PriceList
                                                            product={product}
                                                            oldC="goods-card__price-last"
                                                            newC="goods-card__price-now"
                                                        />
                                                    </p>
                                                    <p className="goods-card__description">
                                                        {product.product_name}
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <button
                                className="swiper-button-next goods-button-next"
                                type="button"
                                tabIndex="0"
                            ></button>
                        </div>
                    </section>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

function ColorList({product, selectedColor, selectColor}) {
    if ( product.price.find((colorItem) => Number(colorItem.color_id) !== 1) ) {
        const key = "color_id";
        const uniquePrice = [
            ...new Map(product.price.map((item) => [item[key], item])).values(),
        ];
        return (
            <>
                <button
                    className="same-part-kt__color"
                    style={{display: "block"}}
                >
                    Rənglər
                </button>
                <div className="same-part-kt__slider-color">
                    <div
                        className="d-flex align-items-center"
                        style={{flexWrap: "wrap"}}
                    >
                        {uniquePrice
                            .filter((colorItem) => Number(colorItem.color_id) !== 1)
                            .map((item) => (
                                <label
                                    key={`color-${item.color_id}`}
                                    className={`color-picker-element ${
                                        Number(item.color_id) === selectedColor && "active"
                                    }`}
                                    onClick={(e) =>
                                        selectColor(e, Number(item.color_id))
                                    }
                                    title={
                                        product.colors.find(
                                            (colorItem) =>
                                                colorItem.id === Number(item.color_id)
                                        )?.title
                                    }
                                >
									<span
                                        className="colors-list__color"
                                        style={{
                                            backgroundColor: product.colors.find(
                                                (colorItem) =>
                                                    colorItem.id === Number(item.color_id)
                                            )?.name
                                        }}
                                    ></span>
                                </label>
                            ))}
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
}

function SizeList({product, selectedSize, selectedColor, selectSize}) {
    if (product.price.find((item) => item.size_id != null)) {
        const key = "size_id";
        let priceList = product.price;
        if (selectedColor) {
            priceList = product.price.filter(
                (item) => Number(item.color_id) === selectedColor
            );
        }
        const uniquePrice = [
            ...new Map(priceList.map((item) => [item[key], item])).values(),
        ];
        return (
            <>
                <div className="same-part-kt__table-of-sizes">
                    <button className="same-part-kt__show-sizes" type="button">
                        Ölçü cədvəli
                    </button>
                </div>
                <ul className="same-part-kt__sizes-list sizes-list j-size-list">
                    {uniquePrice.map((item) => (
                        <li
                            key={`size-${item.id}`}
                            className="sizes-list__item"
                        >
                            <label
                                className={`j-size ${
                                    Number(item.size_id) === selectedSize && "active"
                                }`}
                                onClick={(e) => selectSize(e, Number(item.size_id))}
                            >
								<span className="sizes-list__size">
									{
                                        product.sizes.find(
                                            (sizeItem) =>
                                                sizeItem.id === Number(item.size_id)
                                        )?.name
                                    }
								</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </>
        );
    } else {
        return null;
    }
}
