import React, { useState, useContext } from 'react'
import { MegaContext } from '../Context/MegaContext';
import { AuthenticationContext } from "../Context/AuthenticationContext";

export default function PopUpName() {
    const authContext = useContext(AuthenticationContext);
    const megaContext = useContext(MegaContext);
    const [data, setData] = useState(authContext.user);

    const handleData = (e) => {
        const { name, value } = e.target;
        let newData = { ...data};
        newData[name] = value;
        setData(newData);

      };
    const formSubmit = (e) => {
        e.preventDefault();
        authContext.updateData(data)
        
      };
    return (
        <>
            <div className="i-popup-confirmCode centered shown popup-edit-name" style={{ maxHeight: 'max-content', top: window.scrollY + 20, left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', zIndex: 301, opacity: 1, display: 'block' }}>
                <a href="#" className="j-close popup__close close" onClick={() => megaContext.setNamePopUp(false)}/>
                <div className="popup__content">
                    <form onSubmit={formSubmit}>
                        <h2 className="popup__header">Ad Soyad dəyiş</h2>
                        <ul className="form-list p-0">
                            <li className="form-list__item form-block">
                                <label className="form-block__label" htmlFor="first_name">
                                    <span className="form-block__name">Ad</span>
                                    <span className="form-block__text-decor">*</span>
                                </label>
                                <input type="text" name="first_name" id="first_name" value={data.first_name} className="val-msg" onChange={handleData} />
                            </li>
                            <li className="form-list__item form-block">
                                <label className="form-block__label" htmlFor="last_name">
                                    <span className="form-block__name">Soyad</span>
                                    <span className="form-block__text-decor">*</span>
                                </label>
                                <input type="text"  name="last_name" id="last_name" value={data.last_name} className="val-msg" onChange={handleData} />
                            </li>
                            </ul>
                        <div className="popup__btn">
                            <button className="btn-main w-100" type="submit" >Saxla</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="i-overlay initially-hidden j-custom-overlay" style={{ zIndex: 300 }} onClick={() => megaContext.setNamePopUp(false)}/>
        </>
    )
}
