import React, { useState, useContext } from 'react'
import { MegaContext } from '../Context/MegaContext';
import { AuthenticationContext } from "../Context/AuthenticationContext";

export default function PopUpMobile() {
  const authContext = useContext(AuthenticationContext);
    const megaContext = useContext(MegaContext);
    const [data, setData] = useState(authContext.user);

    const handleData = (e) => {
        const { name, value } = e.target;
        let newData = { ...data};
        newData[name] = value;
        setData(newData);
      };
    const formSubmit = (e) => {
        e.preventDefault();
        authContext.updateData(data)
        
      };
  return (
    <>
      <div className="i-popup-confirmCode centered shown popup-edit-email" style={{ maxHeight: 'max-content', top: window.scrollY + 20, left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', zIndex: 301, opacity: 1, display: 'block' }}>
        <a href="#" className="j-close popup__close close"  onClick={() => megaContext.setMobilePopUp(false)} />
        <div className="popup__content">
          <h2 className="popup__header">Telefon dəyiş</h2>
          <form onSubmit={formSubmit}>
            <div className="form-block">
              <label className="form-block__label" htmlFor="mobile">
                <span className="form-block__name">Telefon</span>
                <span className="form-block__text-decor">*</span>
              </label>
              <input name="mobile" id="mobile" autofocus="autofocus" className="val-msg" value={data.mobile} onChange={handleData}/>
            </div>
            <div className="popup__btn">
              <button className="btn-main w-100" type="submit">Saxla</button>
            </div>
          </form>
        </div>
      </div>
      <div className="i-overlay initially-hidden j-custom-overlay" style={{ zIndex: 300 }} onClick={() => megaContext.setMobilePopUp(false)} />

    </>
  )
}
