import React, {useState, useContext} from 'react'
import {MegaContext} from '../Context/MegaContext';
import {AuthenticationContext} from "../Context/AuthenticationContext";
import serialize from "form-serialize";
import axios from "axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PersonIcon from "@mui/icons-material/Person";

export default function PopUpLogin() {
    const authContext = useContext(AuthenticationContext);
    const megaContext = useContext(MegaContext);
    const [form, setForm] = useState('login');

    const loginFormSubmit = async event => {

        event.preventDefault();
        // console.log(this.context)
        // return false

        var data = serialize(event.target, {hash: true})
        let cartId = JSON.parse(localStorage.getItem("cart_id"));
        data['cartId'] = cartId


        let response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, data)

        response = response.data

        if (response.status === 'success') {
            window.location.href = '/'
            toast.success(response.message)
            localStorage.setItem('authentication', JSON.stringify(response.authentication))
            this.context.authLogin(response.authentication)
            localStorage.setItem('cart_id', JSON.stringify(response.cart_id))
        }
        if (response.status === 'warning') {
            let message = response.message;
            for (const value of Object.values(message)) {
                for (let i = 0; i < value.length; i++) {
                    toast.warning(value[i])
                }
            }
        }
        if (response.status === 'error') {
            toast.error(response.message)
        }

    }
    const registerFormSubmit = async (event) => {
        event.preventDefault();
        // console.log(this.context)
        // return false

        var data = serialize(event.target, {hash: true});
        let cartId = JSON.parse(localStorage.getItem("cart_id"));
        data['cartId'] = cartId

        let response = await axios.post(
            `${process.env.REACT_APP_API_URL}/register`,
            data
        );

        response = response.data;

        console.log(response)

        if (response.status === "success") {
            toast.success(response.message);
            localStorage.setItem(
                "authentication",
                JSON.stringify(response.authentication)
            );
            localStorage.setItem('cart_id', JSON.stringify(response.cart_id))
            this.context.authLogin(response.authentication);
            window.location.href = "/";
        }
        if (response.status === "warning") {
            let message = response.message;
            for (const value of Object.values(message)) {
                for (let i = 0; i < value.length; i++) {
                    toast.warning(value[i]);
                }
            }
        }
        if (response.status === "error") {
            toast.error(response.message);
        }
    };
    return (
        <>
            <div className="i-popup-confirmCode centered shown popup-edit-name" style={{
                maxHeight: 'max-content',
                top: window.scrollY + 20,
                left: 0,
                right: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
                zIndex: 301,
                opacity: 1,
                display: 'block'
            }}>
                <a href="#" className="j-close popup__close close" onClick={() => megaContext.setPopUpLogin(false)}/>
                <div className="popup__content sign-in-page">
                    {
                        form == 'login' ? (
                            <form onSubmit={loginFormSubmit}>
                                <h2 className="popup__header">Hesabınıza daxil olun</h2>
                                <ul className="form-list p-0">
                                    <li className="form-list__item form-block">
                                        <label className="form-block__label" htmlFor="email">
                                            <span className="form-block__name">Email</span>
                                            <span className="form-block__text-decor">*</span>
                                        </label>
                                        <input type="email" name="email" id="email" className="val-msg"/>
                                    </li>
                                    <li className="form-list__item form-block">
                                        <label className="form-block__label" htmlFor="password">
                                            <span className="form-block__name">Şifrə</span>
                                            <span className="form-block__text-decor">*</span>
                                        </label>
                                        <input type="password" name="password" id="password" className="val-msg"/>
                                    </li>
                                </ul>
                                <br/>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="remember" value="1"
                                           id="remember"/>
                                    <label className="form-check-label" htmlFor="remember">Məni xatırla</label>
                                </div>

                                <div className="popup__btn">
                                    <button className="btn-main w-100" type="submit">Giriş</button>
                                </div>

                                <div className="sign-in-page__public p-0">
                                    <div onClick={() => setForm('register')} style={{'cursor': 'pointer'}}>
                                        <PersonAddAltIcon/> <span style={{verticalAlign: 'middle'}}>Yeni hesab</span>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            <form onSubmit={registerFormSubmit}>
                                <h2 className="popup__header">Hasab yaradın</h2>
                                <ul className="form-list p-0">
                                    <li className="form-list__item form-block">
                                        <label className="form-block__label">
                                            <span className="form-block__name">Ad</span>
                                        </label>
                                        <div className="form-block__dropdown-wrap">
                                            <div className="form-block__input">
                                                <input className="input-item" type="text" name="first_name"/>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="form-list__item form-block">
                                        <label className="form-block__label">
                                            <span className="form-block__name">Soyad</span>
                                        </label>
                                        <div className="form-block__dropdown-wrap">
                                            <div className="form-block__input">
                                                <input className="input-item" type="text" name="last_name"/>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="form-list__item form-block">
                                        <label className="form-block__label">
                                            <span className="form-block__name">Email</span>
                                        </label>
                                        <div className="form-block__dropdown-wrap">
                                            <div className="form-block__input">
                                                <input className="input-item" type="email" name="email"/>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="form-list__item form-block">
                                        <label className="form-block__label">
                                            <span className="form-block__name">Mobil</span>
                                        </label>
                                        <div className="form-block__dropdown-wrap">
                                            <div className="form-block__input">
                                                {/*<InputMask mask="+\9\94 (__) ___ __ __" maskChar={null} className="input-item" type="text" name="mobile"/>*/}
                                                <input className="input-item" type="text" name="mobile"/>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="form-list__item form-block">
                                        <label className="form-block__label">
                                            <span className="form-block__name">Şifrə</span>
                                        </label>
                                        <div className="form-block__dropdown-wrap">
                                            <div className="form-block__input">
                                                <input className="input-item" type="password" name="password"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <div className="popup__btn">
                                    <button className="btn-main w-100" type="submit">Hesab Yarat</button>
                                </div>

                                <div className="sign-in-page__public p-0">
                                    <div onClick={() => setForm('login')} style={{'cursor': 'pointer'}}>
                                        <PersonIcon/>{" "}
                                        <span style={{verticalAlign: "middle"}}>Hesabınız var?</span>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </div>
            </div>
            <div className="i-overlay initially-hidden j-custom-overlay" style={{zIndex: 300}}
                 onClick={() => megaContext.setPopUpLogin(false)}/>
        </>
    )
}
