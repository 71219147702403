import React, {Component, Fragment} from 'react'
import { Link } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination, EffectFade, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, A11y, EffectFade, Autoplay]);


export default class BannerTwo extends Component {

    render() {
        return (
            <Fragment>
                <div className="j-banners-block main-page__promo promo promo--2 promo--slider">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        className='j-promo-banners'
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log('slide change')}
                        autoplay={{ delay: 5000 }}
                        breakpoints={{
                            576: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 10
                            },
                            1200: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            }
                        }}
                    >
                        {
                            this.props.items ? this.props.items.map(item =>
                                <SwiperSlide key={item.id} className="promo__item">
                                    <div className="img-plug banners-zones-container" >
                                        <div className="banners-zones"  >
                                            <Link to={item.banner_slug ? item.banner_slug : '/'} className="j-banner-shown-stat j-banner-click-stat j-banner-wba j-banner"  >
                                                <div className="img-wrap">
                                                    <img src={`${process.env.REACT_APP_URL}assets/img/banners/${item.banner_image}`} alt={item.banner_image}/>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ) : null
                        }
                    </Swiper>
                </div>



            </Fragment>
        )
    }
}

