import { useState } from 'react'

import SliderRepository from '../repositories/SliderRepository';

export default  function useGetSliders() {

    const [slidersItems, setSlidersItems] = useState(null);


    return {
        slidersItems,

        getSliders: async () => {
            let responseData = await SliderRepository.getSliders();
            
            if (responseData) {
                
                setSlidersItems(responseData.data.length > 0 ? responseData.data : null);
            }
        }
    }
}

