import React, { useContext, useEffect, useState } from 'react'
import { MegaContext } from '../../Context/MegaContext';
import useGetCategories from '../../hooks/useGetCategories';
import { useParams, Link, useLocation } from "react-router-dom";
import NotFound from "../NoFound";
import Products from "./Products";
import Helmet from "react-helmet";


function BreadCrumps ({ breadcrumb }) {
    if(!breadcrumb.top_category.id){
        return (
            <ul className="breadcrumbs__list">
                <li className="breadcrumbs__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                    <Link className="breadcrumbs__link" to="/" >Ana Səhifə</Link>
                </li>
                <li className="breadcrumbs__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                    <span itemProp="name">{breadcrumb.category_name}</span>
                </li>
            </ul>
        )
    }
    else{
        if(!breadcrumb.top_category.top_category.id){
            return (
                <ul className="breadcrumbs__list">
                    <li className="breadcrumbs__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                        <Link className="breadcrumbs__link" to="/" >Ana Səhifə</Link>
                    </li>
                    <li className="breadcrumbs__item" >
                        <Link className="breadcrumbs__link" to={`/catalog/${breadcrumb.top_category.slug}`}  >
                            <span itemProp="name">{breadcrumb.top_category.category_name}</span>
                        </Link>
                    </li>
                    <li className="breadcrumbs__item" >
                        <span itemProp="name">{breadcrumb.category_name}</span>
                    </li>
                </ul>
            )
        }
        else{
            return (
                <ul className="breadcrumbs__list">
                    <li className="breadcrumbs__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                        <Link className="breadcrumbs__link" to="/" >Ana Səhifə</Link>
                    </li>
                    <li className="breadcrumbs__item" >
                        <Link className="breadcrumbs__link" to={`/catalog/${breadcrumb.top_category.top_category.slug}`} >
                            <span itemProp="name">{breadcrumb.top_category.top_category.category_name}</span>
                        </Link>
                    </li>
                    <li className="breadcrumbs__item" >
                        <Link className="breadcrumbs__link" to={`/catalog/${breadcrumb.top_category.slug}`}  >
                            <span itemProp="name">{breadcrumb.top_category.category_name}</span>
                        </Link>
                    </li>
                    <li className="breadcrumbs__item" >
                        <span itemProp="name">{breadcrumb.category_name}</span>
                    </li>
                </ul>
            )
        }
    }
}

function changePage (p, slug, pagination, getProducts) {
    if (p < 1) {
        p = 1
    } else if (p > pagination) {
        p = pagination
    }
    getProducts({slug: slug, page: p})
}
function CreatePagination ({pagination, slug, page, getProducts}) {
    let p = []
    if (pagination > 1) {
        p.push(<span className="pagination-prev pagination-item pagination__prev" onClick={() => changePage(page - 1, slug, pagination, getProducts)}><i className="fa fa-chevron-left"></i></span>)
        for (let i = 1; i <= pagination; i++) {
            p.push(<span className={`pagination-item pagination__item ${page === i ? 'active' : ''}`} onClick={() => changePage(i, slug, pagination, getProducts)} >{i}</span>)
        }
        p.push(<span className="pagination-next pagination-item pagination__next" onClick={() => changePage(page + 1, slug, pagination, getProducts)}><i className="fa fa-chevron-right"></i></span>)
    }
    return p;
}

export default function Catalog() {
    const location = useLocation()
    const { slug } = useParams();
    const { products, count, error, loading, pagination, page, setLoading, category, brands, colors, sizes, getCategory, getProducts } = useGetCategories();
    const context = useContext(MegaContext);
    const [select, setSelect] = useState(false)
    const [filterData, setFilterData] = useState(null)

    const quickView = (e, item) => {
        e.preventDefault()
        context.setQuickView(true, item)
    }
    const setIsShown = (e) => {
        e.target.closest('.product-card').classList.add('hover')
    }
    const setIsHide = (e) => {
        e.target.closest('.product-card').classList.remove('hover')
    }

    const filterSelectHandler = (e) => {
        e.target.classList.toggle('selected')
    }
    const filterCategory = () => {
        let brandsInput = document.querySelectorAll('.brands:checked')
        let colorsInput = document.querySelectorAll('.colors:checked')
        let sizesInput = document.querySelectorAll('.sizes:checked')
        let brands = []
        let colors = []
        let sizes = []
        for (let i = 0; i < brandsInput.length; i++) {
            brands.push(brandsInput[i].value)
        }
        for (let i = 0; i < colorsInput.length; i++) {
            colors.push(colorsInput[i].value)
        }
        for (let i = 0; i < sizesInput.length; i++) {
            sizes.push(sizesInput[i].value)
        }

        let newFilterData = filterData ? [...filterData] : []
        if (brands.length > 0) {
            newFilterData['brands'] = brands
        }
        if (colors.length > 0) {
            newFilterData['colors'] = colors
        }
        if (sizes.length > 0) {
            newFilterData['sizes'] = sizes
        }

        setFilterData(newFilterData)
        // console.log(newFilterData)
        getProducts({slug: slug, page: 1, filterData: {...newFilterData}});
    }
    const clearFilter = () => {
        setFilterData(null)

        for (const checkbox of document.querySelectorAll('.filter__item.selected')) {
            checkbox.classList.remove('selected')
            checkbox.querySelector('.product-filter__checkbox').checked = false
        }

        getProducts({slug: slug, page: 1});
    }
    const sortingFilter = (e) => {
        let name = e.target.getAttribute('data-name')
        let order = ''
        if(e.target.getAttribute('data-order') == 'asc'){
            order = "desc"
            e.target.setAttribute('data-order', 'desc')
            e.target.querySelector('.fa').classList.remove('fa-sort-down')
            e.target.querySelector('.fa').classList.add('fa-sort-up')
        }
        else{
            order = "asc"
            e.target.setAttribute('data-order', 'asc')
            e.target.querySelector('.fa').classList.remove('fa-sort-up')
            e.target.querySelector('.fa').classList.add('fa-sort-down')
        }
        let sorting = {sorting_name: name, order: order}

        let newFilterData = filterData ? [...filterData] : []
        newFilterData['sorting'] = sorting
        // console.log(newFilterData)
        setFilterData(newFilterData)
        getProducts({slug: slug, page: 1, filterData: {...newFilterData}});
    }

    const filterShow = () => {
        document.querySelector(".catalog-page__side").classList.add('catalog-page__side--active')
        let div = document.createElement('div');
        // <div className="i-overlay initially-hidden j-custom-overlay" style={{ zIndex: 300 }} onClick={() => context.setQuickView(false)} />
        div.className = "overlay overlay--filter j-overlay"
        div.style.zIndex = 300
        document.querySelector('body').classList.add('body--overflow')

        div.onclick = function () {
            document.querySelector(".catalog-page__side").classList.remove('catalog-page__side--active')
            document.querySelector('.overlay--filter').remove()
            document.querySelector('body').classList.remove('body--overflow')
        }
        document.querySelector('#catalog').prepend(div)
    }

    useEffect(() => {
        getCategory({slug: slug});
        getProducts({slug: slug, page: 1});
    }, [location]);

    if(error){
        return <NotFound />
    }
    else{
        if(category){
            return (
                <>
                    <Helmet>
                        <title>Mega az onlayn alış veriş mağazası</title>
                        <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
                        <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
                    </Helmet>

                    <div id="catalog" className="catalog-page catalog-page--brand">
                        <div className="catalog-page__breadcrumbs">
                            <div >
                                <div className="breadcrumbs">
                                    <div className="breadcrumbs__container">
                                        <BreadCrumps breadcrumb={category} />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="brand-custom-header">
                            <div id="name" className="brand-custom-header__title">
                                <h1 className="brand-custom-header__name" >{ category.category_name}</h1>
                                <span className="brand-custom-header__count" >
                                    <span>{count} </span> <span>məhsul</span>
                                </span>
                            </div>
                        </div>


                        <div className="catalog-page__side">
                            <div id="filters" className="catalog-page__filters" >
                                {
                                    category.alt_category.length > 0 ? <div className="j-filter-container filter filterblock render_type_1 xsubject show filter-active" >
                                        <div className="filter__title j-b-city-dropdown j-filter-title">
                                            <span className="filter__name">Alt kategoriyalar</span>
                                        </div>
                                        <div className="j-filter-content filter__content ">
                                            <fieldset className="j-list filter__fieldset list_left_xsubject render_type_1 filter__fieldset--limited">
                                            {
                                                category.alt_category.map(item => <p><Link className="j-list-item filter__item" to={`/catalog/${item.slug}`} style={{fontSize: "16px"}} onClick={(e) => filterSelectHandler(e)} >{item.category_name}</Link></p>)
                                            }
                                            </fieldset>
                                        </div>
                                    </div> : null
                                }
                                {
                                    brands ? <div className="j-filter-container filter filterblock render_type_6 fbrand show filter-active" data-filter-name="fbrand">
                                        <div className="filter__title j-b-city-dropdown j-filter-title">
                                            <span className="filter__name">Marka</span>
                                        </div>
                                        <div className="j-filter-content filter__content ">
                                            <fieldset className="j-list filter__fieldset list_left_fbrand render_type_6 filter__fieldset--limited">
                                                {
                                                    brands.map(item => <label className="j-list-item filter__item filter__item--checkbox" onClick={(e) => filterSelectHandler(e)}>{item.name}  ({item.products.length}) <input className="product-filter__checkbox brands" type="checkbox" name="brands" value={item.id} /> </label>)
                                                }
                                            </fieldset>
                                        </div>
                                    </div> : null
                                }
                                {
                                    colors ? <div className="j-filter-container filter filterblock render_type_2 fcolor show filter-active" data-filter-name="fcolor">
                                        <div className="filter__title j-b-city-dropdown j-filter-title">
                                            <span className="filter__name">Rəng</span>
                                        </div>
                                        <div className="j-filter-content filter__content ">
                                            <fieldset className="j-list filter__fieldset list_left_fcolor render_type_2 filter__fieldset--limited">
                                            {
                                                colors.map(item => <label className="j-list-item filter__item filter__item--color" title={item.title} style={{ backgroundColor: item.name }} onClick={(e) => filterSelectHandler(e)}> <input className="product-filter__checkbox colors" type="checkbox" name="colors" value={item.id} /></label>)
                                            }
                                            </fieldset>
                                        </div>
                                    </div> : null
                                }
                                {
                                    sizes ? <div className="j-filter-container filter filterblock render_type_1 fsize show filter-active" data-filter-name="fsize">
                                        <div className="filter__title j-b-city-dropdown j-filter-title">
                                            <span className="filter__name">Ölçü</span>
                                        </div>
                                        <div className="j-filter-content filter__content ">
                                            <fieldset className="j-list filter__fieldset list_left_fsize render_type_1 filter__fieldset--limited">
                                            {
                                                sizes.map(item => <label className="j-list-item filter__item filter__item--checkbox" onClick={(e) => filterSelectHandler(e)}>{item.name}  ({item.products.length}) <input className="product-filter__checkbox sizes" type="checkbox" name="sizes" value={item.id} /></label>)
                                            }
                                            </fieldset>
                                        </div>
                                    </div> : null
                                }

                                {/* <div className="j-filter-container filter filterblock render_type_5 priceU show filter-active" data-filter-name="priceU">
                                    <div className="filter__title j-b-city-dropdown j-filter-title">
                                        <span className="filter__name">Qiymət, ₼</span>
                                    </div>
                                    <div className="j-filter-content filter__content ">
                                        <div className="selectorsblock range">
                                            <div className="range-text">
                                                <div className="start-n">
                                                    <span className="start-text">Min</span>
                                                    <div>
                                                        <input className="j-price c-input-base-sm" type="text" defaultValue={1898} name="startN" data-filter-name="priceU" />
                                                    </div>
                                                </div>
                                                <div className="end-n">
                                                    <span className="end-text">Max</span>
                                                    <div>
                                                        <input className="j-price c-input-base-sm" type="text" defaultValue={2220} name="endN" data-filter-name="priceU" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="j-filter-container filter filterblock render_type_5 priceU show filter-active" data-filter-name="priceU">
                                    <button type="button" className="btn-main-sm j-add-to-basket w-100" onClick={filterCategory}>Filtr</button>
                                    {
                                        filterData ? <button className="clear-filter-btn w-100" onClick={clearFilter}>Filtri  Təmizlə</button> : null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="catalog-page__main common-brand-catalog new-size" >
                            <div className>
                                <div className="inner-sorter">
                                    <div id="catalog_sorter" className="sort" >
                                        <span>Sırala:</span>
                                        <a className="sort-item" onClick={sortingFilter} data-name="sale_price" data-order="asc" href="javascript:void(0)" >Qiymət <i className="fa fa-sort-down" style={{  verticalAlign: 'baseline' }}></i></a>
                                        <a className="sort-item" onClick={sortingFilter} data-name="created_at" data-order="asc" href="javascript:void(0)" >Yeni <i className="fa fa-sort-down" style={{  verticalAlign: 'baseline' }}></i></a>
                                    </div>
                                    <div className="sorter__filter hide-desktop" onClick={() => filterShow()}>Filtr</div>
                                </div>


                                <div className="catalog-page__content" id="catalog-content" >
                                    <div className="product-card-overflow">
                                        <div className="product-card-list">

                                            {
                                                loading ?
                                                <div class="general-preloader mx-auto"></div> :
                                                <>
                                                    <Products products={products} setIsShown={setIsShown} setIsHide={setIsHide} quickView={quickView} />
                                                    <span className="product-card product-card--fake"></span>
                                                    <span className="product-card product-card--fake"></span>
                                                    <span className="product-card product-card--fake"></span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="pager-bottom">
                                    <div className="pager i-pager pagination" >
                                        <div className="pageToInsert pagination__wrapper" >

                                            <CreatePagination pagination={pagination} slug={slug} page={page} getProducts={getProducts} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else{
            return <div class="general-preloader"></div>
        }
    }

}
