import React, {useContext, useEffect} from 'react'
import {Link, useNavigate, useLocation, useParams} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useGetOrders from "../../hooks/useGetOrders";
import {AuthenticationContext} from "../../Context/AuthenticationContext";
import Helmet from "react-helmet";

export default function Order() {
    const authContext = useContext(AuthenticationContext);
    const {  order, getOrder } = useGetOrders();
    const location = useLocation();
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            getOrder({user_id: authContext.user.id, order_id: id});
        }
    }, [location]);
    return (
        order ?
        <>
            <Helmet>
                <title>Mega az onlayn alış veriş mağazası</title>
                <meta name="description" content="MegaAZ onlayn alış veriş mağazası 2021-ci ildə yaradılmış onlayn alış-veriş mağazasıdır." />
                <meta name="keyword" content="onlayn mağaza, onlayn ticarət, onlayn sifariş, online alış, online magaza azerbaycan, onlayn bazar" />
            </Helmet>

            <div className="py-3">
                <Link to="/account/orders" className="breadcrumbs__back btn-base-sm" ><ArrowBackIcon /> <span style={{ verticalAlign: 'middle' }}>Geri</span></Link>
            </div>
            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Məhsul</th>
                            <th className="text-center">Qiymət</th>
                            <th className="text-center">Məlumat</th>
                            <th className="text-center">Miqdar</th>
                            <th className="text-center">Ümumi Qiymət</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        (order.cart && order.cart.cart_products) && order.cart.cart_products.map((product, index) => (
                            <tr>
                                <td className="text-center">
                                    <Link to={`/product/${product.product?.slug}`}>
                                        <img
                                            src={
                                                product.product?.images?.length > 0
                                                    ? `${process.env.REACT_APP_URL}assets/img/products/${product.product.images[0].image_name}`
                                                    : null
                                            }
                                            alt={
                                                product.product?.images?.length > 0
                                                    ? product.product.images[0].image_name
                                                    : null
                                            }
                                            style={{ width: 100 }}
                                        />
                                        <p>{product.product?.product_name}</p>
                                    </Link>
                                </td>
                                <td className="text-center">
                                    {product.price?.sale_price} <i>₼</i>
                                </td>
                                <td className="text-center">
                                    {product.size_id && <p>Ölçü: <span>{product.size?.name}</span></p>}
                                    {product.color_id && <p>Rəng: <span style={{ backgroundColor: product.color.name }} className='badge'>{product.color.title}</span></p>}
                                </td>
                                <td className="text-center">
                                    {product.piece}
                                </td>
                                <td className="text-center">
                                    {product.price?.sale_price} <i>₼</i>
                                </td>
                            </tr>
                        ))
                    }

                    </tbody>
                </table>
            </div>

            <div className='col-12'>
                <div className="row">
                    <div className="col text-center">
                        <p>YEKUN MƏBLƏĞ</p>
                        <p>{order.order_amount} <i>₼</i></p>
                    </div>
                    <div className="col text-center">
                        <p>GÖNDƏRMƏ</p>
                        <p>{ order.shipping ? <>{order.shipping} <i>₼</i></> : 'Pulsuz çatdırılma' } </p>
                    </div>
                    <div className="col text-center">
                        <p>TARIX</p>
                        <p>{order.created_at}</p>
                    </div>
                </div>
            </div>


        </>
            : <div className="general-preloader"></div>
    )
}
