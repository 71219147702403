import React from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import Home from "./Pages/home/Home";
import Cart from "./Pages/cart/Cart";
import Privacy from "./Pages/Privacy";
import ShippingReturn from "./Pages/ShippingReturn";
import BestSelling from "./Pages/home/BestSelling";
import Discounts from "./Pages/home/Discounts";
import Brands from "./Pages/brand/Brands";
import BrandProduct from "./Pages/brand/BrandProduct";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Catalog from "./Pages/catalog/Catalog";
import Product from "./Pages/product/Product";
import Login from "./Pages/auth/Login";
import Register from "./Pages/auth/Register";
import Reset from "./Pages/auth/Reset";
import Auth from "./Pages/auth/Auth";
import Account from "./Pages/account/Account";
import Profile from "./Pages/account/Profile";
import WishList from "./Pages/account/WishList";
import Orders from "./Pages/account/Orders";
import Order from "./Pages/account/Order";
import NoFound from "./Pages/NoFound";

const AppRoutes = ({contextAuth}) => {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/cart" element={<Cart/>}/>
            {/*<Route path="/search" element={<Search />} />*/}
            <Route
                path="/privacy"
                element={<Privacy/>}
            />
            <Route
                path="/shipping_return"
                element={<ShippingReturn/>}
            />
            <Route path="/about"
                   element={<About/>}/>
            <Route
                path="/contact"
                element={<Contact/>}
            />
            <Route
                path="/best-selling"
                element={<BestSelling/>}
            />
            <Route
                path="/discounts"
                element={<Discounts/>}
            />
            <Route path="/brands"
                   element={<Brands/>}/>
            <Route
                path="/brand/:slug"
                element={<BrandProduct/>}
            />
            <Route
                path="/catalog/:slug"
                element={<Catalog/>}
            />
            {/* <Route path="/catalog/:top/:sub" element={<Catalog />} /> */}
            <Route
                path="/product/:slug"
                element={<Product/>}
            />

            <Route
                path="auth"
                element={contextAuth.isAuthenticated ? <Navigate replace to="/account" /> : <Auth/>}
            >
                <Route index
                       path="login"
                       element={<Login/>}
                />
                <Route
                    path="register"
                    element={<Register/>}
                />
                <Route
                    path="reset"
                    element={<Reset/>}
                />
            </Route>

            <Route
                path="account"
                element={!contextAuth.isAuthenticated ? <Navigate replace to="/auth/login" /> : <Account/>}
            >
                <Route index element={<Profile/>}/>
                <Route
                    path="wishlist"
                    element={<WishList/>}
                />
                <Route
                    path="orders"
                    element={<Orders/>}
                />
                <Route
                    path="order/:id"
                    element={<Order/>}
                />
            </Route>

            <Route path="*" element={<NoFound/>}/>
        </Routes>
    )
}

export default AppRoutes